import {Control} from "react-hook-form";
import {Box, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import GenericSelect from "../dropdowns/GenericSelect";
import {DropDownItem} from "../../../api/basedataApiClient";
import GenericTextField from "./GenericTextField";
import GenericSwitch from "../../ad-planning/dropdowns/GenericSwitch";
import {
    ArticleAssignment, DetailedPlanningPosition,
    getPaperSizes,
} from "../../../api/detailedPlanningApiClient";
import {useCallback, useEffect, useState} from "react";
import {PermissionGuard} from "../../../components/PermissionGuard";
import HistoryTooltip from "./HistoryTooltip";
import * as React from "react";
import SecurityUtil from "../../../helper/securityUtil";

interface AssignmentDetailsProps {
    assignment: ArticleAssignment | null;
    name: string;
    control: Control<any>;
    isPoster: number | boolean | undefined;
    articleIndex: number;
    position: DetailedPlanningPosition;
}

const useStyles = makeStyles((theme) => ({
    hasHistory: {
        background: "rgba(255, 0, 0, 0.18)",
        display: "flex",
        position: "relative",
    },
}));

const AssignmentDetails = ({
                               assignment,
                               control,
                               name,
                               isPoster,
                               articleIndex,
                               position
                           }: AssignmentDetailsProps) => {
    const {t} = useTranslation();
    const [paperSizes, setPaperSizes] = useState<DropDownItem[]>();
    const classes = useStyles();

    const fetchPaperSizes = useCallback(async () => {
        try {
            const paperSizes = await getPaperSizes();
            setPaperSizes(paperSizes);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const getHistoryAssignment = (assignmentIndex: number) => {
        if (position.articleHistoryAssignments) {
            return position.articleHistoryAssignments.find((aha) => (aha.subPosition === assignmentIndex)) || null
        }
        return null;
    }

    useEffect(() => {
        fetchPaperSizes();
    }, [fetchPaperSizes]);

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                sx={{
                    padding: "1rem 0",
                    marginBottom: "1rem",
                    justifyContent: "end",
                }}
            >
                <Box width="70%" marginRight={2}
                     className={getHistoryAssignment(articleIndex) && getHistoryAssignment(articleIndex)?.processingNote !== assignment?.processingNote && !getHistoryAssignment(articleIndex)?.externalAdded ? classes.hasHistory : ""}>
                    <GenericTextField
                        label={t("handling-note")}
                        control={control}
                        name={name + ".processingNote"}
                        disabled={!assignment}
                    />
                    <HistoryTooltip propName={"processingNote"}
                                    articleHistoryAssignment={getHistoryAssignment(articleIndex)} article={assignment}/>
                </Box>
                <Box width="30%" marginRight={2}>
                    <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                        <GenericTextField
                            label={t("extra-note")}
                            control={control}
                            name={name + ".graphicNote"}
                            disabled={!assignment}
                        />
                    </PermissionGuard>
                </Box>
                <Box marginRight={2}>
                    <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                        <GenericSwitch
                            control={control}
                            name={name + ".poster"}
                            label={t("poster")}
                            disabled={!assignment}
                        />
                    </PermissionGuard>
                </Box>
                <Box width="12em">
                    <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                        {paperSizes && (
                            <GenericSelect
                                label={t("poster-dimensions")}
                                control={control}
                                options={paperSizes}
                                name={name + ".paperSize"}
                                disabled={!assignment || !isPoster}
                            />
                        )}
                    </PermissionGuard>
                </Box>
            </Box>
        </>
    );
};

export default AssignmentDetails;
