import Header from "./Header";
import SideNavigation from "./SideNavigation";
import ContentContainer from "./ContentContainer";

interface LayoutProps {
  children: React.ReactNode;
}

// function ErrorComponent(result: MsalAuthenticationResult) {
//   return <p>An Error Occurred: {result.error?.message}</p>;
// }
//
// function LoadingComponent() {
//   return <p>Authentication in progress...</p>;
// }

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      {/*<MsalAuthenticationTemplate*/}
      {/*  interactionType={InteractionType.Redirect}*/}
      {/*  authenticationRequest={authRequest}*/}
      {/*  errorComponent={ErrorComponent}*/}
      {/*  loadingComponent={LoadingComponent}*/}
      {/*>*/}
        <SideNavigation />
        <ContentContainer>{children}</ContentContainer>
      {/*</MsalAuthenticationTemplate>*/}
    </>
  );
};

export default Layout;
