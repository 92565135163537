import {makeStyles} from "@material-ui/core/styles";
import {Drawer, CssBaseline} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import routes from "../../router/routes";
import {getSchedules} from "../../api/adplanApiClient";
import {useState, useCallback, useEffect} from "react";
import {AdvertisingSchedule} from "../../dto/Adplan";
import {useTranslation} from "react-i18next";
import {PermissionGuard} from "../../components/PermissionGuard";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../../helper/securityUtil";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        marginTop: theme.spacing(4),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },

    navLinkStyles: {
        display: "flex",
        color: "gray",
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },

    disabledLink: {
        pointerEvents: "none",
    },
}));

const SideNavigation = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [schedules, setSchedules] = useState<AdvertisingSchedule[]>([]);
    const { keycloak } = useKeycloak();

    const fetchSchedules = useCallback(async () => {
        if (!SecurityUtil.isMarktbetreiber(keycloak)) {
            try {
                const response = await getSchedules();
                setSchedules(response);
            } catch (e) {
                console.log(e);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchSchedules();
    }, [fetchSchedules]);

    const isLinkDisabled = (length: number, path: string) => {
        return (
            length === 0 && path === `${("/" + t("detailed-planning")).toLowerCase()}`
        );
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Drawer variant="permanent">
                <div className={classes.toolbar}></div>
                {routes.map((route, index) => (
                    <div key={index}>
                        <PermissionGuard hideForRoles={route.hideForRoles}>
                            <NavLink
                                className={`${
                                    isLinkDisabled(schedules.length, route.path)
                                        ? classes.disabledLink
                                        : ""
                                } ${classes.navLinkStyles}`}
                                style={({isActive}) => ({
                                    color: isActive ? "#0d47a1" : "inherit",
                                    backgroundColor: isActive ? "#e3f2fd" : "inherit",
                                })}
                                to={route.path}
                            >
                                <route.icon/>
                            </NavLink>
                        </PermissionGuard>
                    </div>
                ))}
            </Drawer>
        </div>
    );
};

export default SideNavigation;
