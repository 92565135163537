import Keycloak from "keycloak-js";

class SecurityUtil {
    static MARKTBETREIBER = "ppi-media-werbeplanungstool:Marktbetreiber";

    static ROLES = [
        SecurityUtil.MARKTBETREIBER
    ];

    static getRoles(keycloak: Keycloak): string[] {
        return this.isMarktbetreiber(keycloak) ? [SecurityUtil.MARKTBETREIBER] : [];
    }

    static isMarktbetreiber(keycloak: Keycloak): boolean {
        return this.hasRole(keycloak);
    }

    private static hasRole(keycloak: Keycloak): boolean {
        //console.info(keycloak.tokenParsed);
        let role = keycloak.tokenParsed?.ActiveOrganization?.role;

        if (!role) {
            role = keycloak.tokenParsed?.activeOrganization?.role;
        }

        return role && role.includes(SecurityUtil.MARKTBETREIBER);
    }
}

export default SecurityUtil;
