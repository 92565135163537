import {NumericFormat} from "react-number-format";
import {Controller} from "react-hook-form";
import {TextField} from "@material-ui/core";

interface CurrencyInputProps {
    name: string;
    label?: string;
    control: any;
    disabled?: boolean;
    readOnly?: boolean;
    textAlighEnd?: boolean;
    onKeyDown?: (event: any) => void;
    className?: string;
}

const CurrencyInput = ({
                           name,
                           label,
                           control,
                           disabled,
                           readOnly,
                           textAlighEnd,
                           onKeyDown,
                           className
                       }: CurrencyInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, ref}}) => {
                if (value) {
                    value = value / 100;
                }
                return (
                    <NumericFormat
                        readOnly={readOnly}
                        customInput={TextField}
                        size="small"
                        label={label}
                        variant="outlined"
                        InputLabelProps={{shrink: true}}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                        value={value || ""}
                        onValueChange={(values: any) => {
                            onChange(values?.floatValue ? Math.round((values.floatValue || 0) * 100) : "");
                        }}
                        inputProps={{
                            readOnly,
                            style: textAlighEnd ? {textAlign: "end"} : undefined,
                        }}
                        inputRef={ref}
                        prefix="€ "
                        decimalScale={2}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        className={className}
                    />
                );
            }}
        />
    );
};

export default CurrencyInput;
