import {TableCell} from "@material-ui/core";
import {ConnectableElement, useDrag, useDrop} from "react-dnd";
import {InsertionArticle} from "./ArticleRow";
import LinkIcon from "@mui/icons-material/Link";
import {UnlistedArticleProps} from "./UnlistedArticle";
import {
    DetailedPlanningPosition,
    PreviousAvpPriceResult,
} from "../../../api/detailedPlanningApiClient";
import {ReactElement, RefObject} from "react";
import {Box} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../../../helper/securityUtil";

interface EmptyRowProps {
    index: number;
    disabled: boolean;
    getAvpPriceResult: (articleId: number) => void;
    handleUnlistedDrop: (item: UnlistedArticleProps, index: number) => void;
    handleDrop: (
        insertionArticle: InsertionArticle,
        positionIndex: number,
        avpPriceResult: PreviousAvpPriceResult | undefined
    ) => void;
    verticalRef: RefObject<HTMLDivElement>;
    drop: (
        elementOrNode: ConnectableElement,
        options?: any
    ) => ReactElement | null;
    handlerId: string | symbol | null;
    pageIndex: number;
    isActiveDrop: { isOver: boolean; canDrop: boolean };
    externalDeleted: boolean;
}

const EmptyRow = ({
                      isActiveDrop,
                      index,
                      getAvpPriceResult,
                      disabled,
                      handleUnlistedDrop,
                      handleDrop,
                      verticalRef,
                      drop,
                      handlerId,
                      pageIndex,
                      externalDeleted
                  }: EmptyRowProps) => {
    const [{isOver, canDrop}, dropRef] = useDrop(() => ({
        accept: ["article", "unlistedArticle"],
        drop: (item: any) => {
            if (item.unlistedArticleName) {
                handleUnlistedDrop(item as UnlistedArticleProps, index);
            } else if (item.article) {
                (async () => {
                    const avpPrice = (await getAvpPriceResult(
                        item.article.articleId as number
                    )) as any;
                    handleDrop(item, index, avpPrice);
                })();
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    // eslint-disable-next-line
    const [isDragging, drag] = useDrag<DetailedPlanningPosition>({
        type: "assignedArticle",
        item: () => {
            return {
                index: index,
                articleAssignments: [],
                linkedPositions: [],
            };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(verticalRef));

    const { keycloak } = useKeycloak();

    return (
        <>
            <TableCell
                style={{
                    fontWeight: "bold",
                    border: 0,
                    height: "53px",
                    backgroundColor: externalDeleted ? "initial" : disabled ? "lightgrey" : "white",
                }}
            >
                {index + 1}
            </TableCell>
            <TableCell
                key={"drop-empty-table-cell-" + index}
                style={{
                    backgroundColor: disabled
                        ? "lightgrey"
                        : canDrop
                            ? "rgba(244, 244, 244, 1)"
                            : undefined,
                    boxShadow: isOver ? "inset 0px 0px 3px #1976d2" : "none",
                }}
                colSpan={7}
                ref={disabled ? undefined : dropRef}
            >
                <LinkIcon
                    fontSize="small"
                    style={{display: disabled ? "block" : "none", margin: "auto"}}
                />
            </TableCell>
            {SecurityUtil.isMarktbetreiber(keycloak) ?
                (<TableCell style={{
                    fontWeight: "bold",
                    border: 0,
                    backgroundColor: "white",
                    boxShadow: "none",
                }}></TableCell>)
                :
                (<TableCell
                    ref={verticalRef}
                    data-handler-id={handlerId}
                    key={`draggable-cell-${index}`}
                    style={{
                        fontWeight: "bold",
                        border: 0,
                        backgroundColor: externalDeleted ? "initial" : isActiveDrop.canDrop
                            ? "rgba(244, 244, 244, 1)"
                            : "white",
                        boxShadow: isActiveDrop.isOver ? "inset 0px 0px 3px #1976d2" : "none",
                        cursor: "move",
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <DragIndicatorIcon sx={{padding: 0}}/>
                    </Box>

                </TableCell>)
            }
        </>
    );
};

export default EmptyRow;
