import {makeStyles, TableRow} from "@material-ui/core";
import type {Identifier} from "dnd-core";
import {useRef} from "react";
import {useFormContext} from "react-hook-form";
import {
    DetailedPlanningPosition,
    PreviousAvpPriceResult,
} from "../../../api/detailedPlanningApiClient";
import {FormValues} from "../../layout/DetailedPlanningDetailsLayout";
import {InsertionArticle} from "./ArticleRow";
import {UnlistedArticleProps} from "./UnlistedArticle";
import {useDrop} from "react-dnd";
import NestedAssignments from "./NestedAssignments";
import EmptyRow from "./EmptyRow";
import HistoryTooltip from "./HistoryTooltip";
import * as React from "react";

interface NestedAssignmentsTableRowProps {
    position: DetailedPlanningPosition;
    setLastAddedArticlePosition: React.Dispatch<React.SetStateAction<number | undefined>>;
    lastAddedArticlePosition: number | undefined;
    pageIndex: number;
    positionIndex: number;
    selectedFieldIndex: string | null;
    setSelectedFieldIndex: (value: string | null) => void;
    getAvpPriceResult: (articleId: number) => void;
    setFieldsChanged: (value: boolean) => void;
    handleUnlistedDrop: (item: UnlistedArticleProps, index: number) => void;
    handleDrop: (
        insertionArticle: InsertionArticle,
        positionIndex: number,
        avpPriceResult: PreviousAvpPriceResult | undefined
    ) => void;
    handleDragEnd: (dragIndex: number, hoverIndex: number) => void;
}

const useStyles = makeStyles((theme) => ({
    hasHistory: {
        background: "rgba(255, 0, 0, 0.18)",
        position: "relative"
    },
}));

const NestedAssignmentsTableRow = ({
                                       position,
                                       pageIndex,
                                       positionIndex,
                                       selectedFieldIndex,
                                       lastAddedArticlePosition,
                                       setLastAddedArticlePosition,
                                       setSelectedFieldIndex,
                                       setFieldsChanged,
                                       getAvpPriceResult,
                                       handleUnlistedDrop,
                                       handleDrop,
                                       handleDragEnd,
                                   }: NestedAssignmentsTableRowProps) => {
    const {watch} = useFormContext<FormValues>();
    const watchedPositions = watch(`pages.${pageIndex}.positions`) || [];
    const classes = useStyles();
    const watchedFields = watch(
        `pages.${pageIndex}.positions.${positionIndex}.articleAssignments`
    );

    const verticalRef = useRef<HTMLDivElement>(null);
    const [{isOver, handlerId, canDrop}, drop] = useDrop<DetailedPlanningPosition,
        void,
        { isOver: boolean; handlerId: Identifier | null; canDrop: boolean }>({
        accept: "assignedArticle",
        drop: (item: any, monitor) => {
            // setTimeout(() => {
            if (item.articleAssignments) {
                if (!verticalRef.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = positionIndex;
                if (dragIndex === hoverIndex) {
                    return;
                }
                if (dragIndex !== undefined) {
                    handleDragEnd(dragIndex, hoverIndex);
                }
            }
            // }, 100);
        },
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
            };
        },
    });

    const isActiveDrop = {isOver: isOver, canDrop: canDrop};

    const getHistoryAssignment = (assignmentIndex: number) => {
        if (position.articleHistoryAssignments) {
            return position.articleHistoryAssignments.find((aha) => (aha.subPosition === assignmentIndex)) || null
        }
        return null;
    }

    return (
        <>
            {position.articleAssignments?.length ? (
                watchedFields?.map((field, assignmentIndex) => (
                    <NestedAssignments
                        isActiveDrop={isActiveDrop}
                        field={field}
                        assignmentIndex={assignmentIndex}
                        lastAddedArticlePosition={lastAddedArticlePosition}
                        setLastAddedArticlePosition={setLastAddedArticlePosition}
                        handleUnlistedDrop={handleUnlistedDrop}
                        key={`position-${positionIndex}-field-${assignmentIndex}`}
                        pageIndex={pageIndex}
                        positionIndex={positionIndex}
                        selectedFieldIndex={selectedFieldIndex}
                        setSelectedFieldIndex={setSelectedFieldIndex}
                        handleDrop={handleDrop}
                        getAvpPriceResult={getAvpPriceResult}
                        setFieldsChanged={setFieldsChanged}
                        handleDragEnd={handleDragEnd}
                        verticalRef={verticalRef}
                        drop={drop}
                        handlerId={handlerId}
                        articleHistoryAssignment={getHistoryAssignment(assignmentIndex)}
                    />
                ))
            ) : (
                <>
                    <TableRow key={"empty-position-" + positionIndex} className={ position.articleHistoryAssignments &&  position.articleHistoryAssignments?.length > 0 ? classes.hasHistory : ""}>
                        <EmptyRow
                            key={"empty-position-row" + positionIndex}
                            isActiveDrop={isActiveDrop}
                            pageIndex={pageIndex}
                            handleUnlistedDrop={handleUnlistedDrop}
                            getAvpPriceResult={getAvpPriceResult}
                            index={positionIndex}
                            handleDrop={handleDrop}
                            disabled={watchedPositions
                                .flatMap((c) => c.linkedPositions)
                                .includes(positionIndex)}
                            verticalRef={verticalRef}
                            drop={drop}
                            handlerId={handlerId}
                            externalDeleted={(position.articleHistoryAssignments && position.articleHistoryAssignments?.length > 0)!!}
                        />
                        <HistoryTooltip articleHistoryAssignment={position.articleHistoryAssignments ? position.articleHistoryAssignments[0] : null} />
                    </TableRow>
                </>
            )}
        </>
    );
};
export default NestedAssignmentsTableRow;
