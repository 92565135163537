import {Switch, FormControlLabel} from "@material-ui/core";
import {Controller} from "react-hook-form";
import useLocalStorage from "../../../hooks/useLocalStorage";

const GenericSwitch = ({
                           name,
                           label,
                           control,
                           disabled,
                           localStorage = false,
                       }: {
    name: string;
    label?: string;
    control: any;
    disabled?: boolean;
    localStorage?: boolean;
}) => {
    const [actualValue, setActualValue] = useLocalStorage<string>(
        `tbg-select-${name}`,
        ""
    );
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, onBlur, value, name, ref}}) => {
                return (
                    <FormControlLabel
                        control={
                            <Switch
                                name={name}
                                color="primary"
                                checked={actualValue === "true" ? true : false || value || false}
                                onChange={
                                    (e, data) => {
                                        if (localStorage) {
                                            setActualValue(data.toString());
                                        }
                                        onChange(data);
                                    }
                                }
                                disabled={disabled}
                            />
                        }
                        label={label}
                    />
                );
            }}
        />
    )
};


export default GenericSwitch;
