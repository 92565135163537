import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { TextField, MenuItem, Chip } from "@mui/material";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../../../helper/securityUtil";

interface StatusDropdownProps {
  name: string;
  control: any;
  localStorage?: boolean;
  disableUnknown?: boolean;
  showEmptyOption?: boolean;
}

interface StatusProps {
  name: STATUS_TYPE;
  backgroundColor?: string;
}

export enum STATUS_TYPE {
  UNKNOWN = "UNKNOWN",
  IN_GRAPHIC = "IN_GRAPHIC",
  FINISHED = "FINISHED",
  FINISHED_MEDIA = "FINISHED_MEDIA",
  IN_PROGRESS = "IN_PROGRESS",
  IN_RELEASE = "IN_RELEASE",
  CRITICAL = "CRITICAL",
  READY = "READY",
}

const STATUS: StatusProps[] = [
  {
    name: STATUS_TYPE.UNKNOWN,
    backgroundColor: "grey",
  },
  {
    name: STATUS_TYPE.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    name: STATUS_TYPE.READY,
    backgroundColor: "orange",
  },
  {
    name: STATUS_TYPE.IN_RELEASE,
    backgroundColor: "lightgreen",
  },
  {
    name: STATUS_TYPE.FINISHED,
    backgroundColor: "green",
  },
  {
    name: STATUS_TYPE.FINISHED_MEDIA,
    backgroundColor: "lightblue",
  },
  {
    name: STATUS_TYPE.IN_GRAPHIC,
    backgroundColor: "blue",
  },
  {
    name: STATUS_TYPE.CRITICAL,
    backgroundColor: "red",
  },
];

const STATUS_CLIENT: StatusProps[] = [
  {
    name: STATUS_TYPE.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    name: STATUS_TYPE.READY,
    backgroundColor: "orange",
  },
  {
    name: STATUS_TYPE.IN_RELEASE,
    backgroundColor: "lightgreen",
  },
];

const StatusDropdown = ({
  name,
  control,
  localStorage = false,
  disableUnknown = false,
  showEmptyOption = true,
}: StatusDropdownProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [statusOptions, setStatusOptions] = useState<StatusProps[]>([]);
  const [IsUnauthorized, setIsUnauthorized] = useState(false);
  const stateSelected = useWatch({ control, name: "templateAndState.state" });
  const [actualValue, setActualValue] = useLocalStorage<string>(
    `tbg-select-${name}`,
    ""
  );

  const filterStatus = (status: StatusProps[]) => {
    return disableUnknown
      ? status.filter((status) => status.name !== "UNKNOWN")
      : status;
  };

  const onLoad = async () => {
    if (keycloak.authenticated) {
      if (SecurityUtil.isMarktbetreiber(keycloak)) {
        setIsUnauthorized(true);
        if (stateSelected === STATUS_TYPE.IN_PROGRESS) {
          setStatusOptions(filterStatus(STATUS_CLIENT));
        } else {
          setStatusOptions(filterStatus(STATUS));
        }
      } else {
        setIsUnauthorized(false);
        setStatusOptions(filterStatus(STATUS));
      }
    } else {
      setStatusOptions(filterStatus(STATUS));
      setIsUnauthorized(false);
    }
  };

  useEffect(() => {
    onLoad();
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            select
            id="select"
            value={actualValue || field.value || ""}
            onChange={(e) => {
              field.onChange(e.target.value);
              if (localStorage) {
                setActualValue(e.target.value);
              }
            }}
            disabled={IsUnauthorized && field.value !== STATUS_TYPE.IN_PROGRESS}
            label={t("status")}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            {showEmptyOption && (
              <MenuItem value="">
                <em>{t("empty-option")}</em>
              </MenuItem>
            )}
            {statusOptions.map((option, index) => {
              return (
                <MenuItem key={index} value={option.name}>
                  <Chip
                    size="small"
                    label={``}
                    style={{
                      backgroundColor: option.backgroundColor,
                      width: "16px",
                      height: "16px",
                      marginRight: "5px",
                    }}
                  />
                  {t(
                    option.name.toLowerCase().replace(/_/g, "-") +
                      "-status-option"
                  )}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default StatusDropdown;
