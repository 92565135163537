import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import theme from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import "./i18n";
import "./app.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";


const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  // silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", // TODO - review (does not work with production build)
  pkceMethod: "S256",
  scope: "openid email"
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
          <App />
      </SnackbarProvider>
    </ThemeProvider>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
