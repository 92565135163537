import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DetailedPlanningPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t("detailed-planning")} - Team Beverage`;
  });
  return <></>;
};

export default DetailedPlanningPage;
