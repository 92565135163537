import {
    Box,
    Button,
    CircularProgress,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableContainer,
} from "@material-ui/core";

import {useTranslation} from "react-i18next";
import CustomerTableRow from "./CustomerTableRow";
import {
    Clienteles,
    DetailedPlanningState,
    upsertClienteleStates,
} from "../../../api/detailedPlanningApiClient";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useCallback, useEffect, useRef, useState} from "react";
import StatusDropdown from "../dropdowns/StatusDropdown";
import {useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import {Grid} from "@mui/material";

interface ClientelesTableProps {
    clienteles: Clienteles[];
    getClientelesCallback: () => Promise<void>;
}

interface FormValues {
    state: DetailedPlanningState;
}

const ClientelesTable = ({
                             clienteles,
                             getClientelesCallback,
                         }: ClientelesTableProps) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const numberOfItemsAdded = 100;
    const {enqueueSnackbar} = useSnackbar();
    const [numberOfItemsShown, setNumberOfItemsShown] = useState(20);
    const [numberOfItemsLeft, setNumberOfItemsLeft] = useState(1);
    const observer = useRef<any>();
    const [hasMore, setHasMore] = useState(false);
    const [openDate, setOpenDate] = useState<number | undefined>(undefined);
    const [selectedClienteles, setSelectedClienteles] = useState<Clienteles[]>(
        []
    );

    const {control, handleSubmit, reset, watch} = useForm<FormValues>({
        defaultValues: {
            state: "" as DetailedPlanningState,
        },
    });

    const lastClientElementRef = useCallback(
        (node: Element) => {
            if (numberOfItemsLeft === 0) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                setHasMore(numberOfItemsLeft > 0);
                if (entries[0].isIntersecting && hasMore) {
                    setNumberOfItemsShown(numberOfItemsShown + numberOfItemsAdded);
                    if (numberOfItemsLeft - numberOfItemsAdded < 1) {
                        setNumberOfItemsLeft(0);
                    } else {
                        setNumberOfItemsLeft(numberOfItemsLeft - numberOfItemsAdded);
                    }
                }
            });
            if (node) {
                observer.current.observe(node);
            }
        },
        [numberOfItemsLeft, hasMore, numberOfItemsShown]
    );

    const onSubmit = async (data: FormValues) => {
        const payload = selectedClienteles.map((clientele) => ({
            year: clientele.year,
            weekOfYear: clientele.weekOfYear,
            version: clientele.version,
            clientele: clientele.clientele,
            state: data.state || DetailedPlanningState.UNKNOWN,
        }));
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let result = await upsertClienteleStates(payload);
            setSelectedClienteles([]);
            await getClientelesCallback();
            enqueueSnackbar(t("successfully-applied"), {variant: "success"});
        } catch (e) {
            const error = e as Error;
            enqueueSnackbar(error.message, {variant: "error"});
            reset({});
        }
    };

    const toggleDate = (index: number) => {
        if (openDate === index) {
            setOpenDate(undefined)
        } else {
            setOpenDate(index);
        }
    }

    const handleAbort = () => {
        setSelectedClienteles([]);
    };

    useEffect(() => {
        setNumberOfItemsLeft(clienteles.length);
    }, [clienteles]);

    return (
        <Box className={classes.tableContainerStyles}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TableContainer
                    component={Paper}
                    className={`${classes.container} ${
                        selectedClienteles.length > 0 ? classes.withButtons : ""
                    }`}
                >
                    <Table stickyHeader size="small" aria-label={t("collapsible-table")}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={"5%"}></TableCell>
                                <TableCell width={"40%"}>{t("customer")}</TableCell>
                                <TableCell width={"15%"}>{t("advertising-week")}</TableCell>
                                <TableCell width={"40%"}>
                                    {t("implementation-period")}
                                </TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clienteles.slice(0, numberOfItemsShown).map((item, index) => {
                                if (numberOfItemsShown - 1 === index) {
                                    return (
                                        <CustomerTableRow
                                            openDate={index === openDate}
                                            toggleDate={toggleDate}
                                            key={index}
                                            index={index}
                                            clienteles={item}
                                            ref={lastClientElementRef}
                                            selectedClienteles={selectedClienteles}
                                            setSelectedClienteles={setSelectedClienteles}
                                        />
                                    );
                                } else {
                                    return (
                                        <CustomerTableRow
                                            openDate={index === openDate}
                                            toggleDate={toggleDate}
                                            index={index}
                                            selectedClienteles={selectedClienteles}
                                            setSelectedClienteles={setSelectedClienteles}
                                            key={index}
                                            clienteles={item}
                                        />
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                    {numberOfItemsLeft - numberOfItemsShown > 0 && (
                        <CircularProgress className={classes.CircularProgressStyles}/>
                    )}
                    <Box
                        className={`${classes.totalBox} ${
                            selectedClienteles.length > 0 ? classes.totalSpacing : ""
                        }`}
                    >
                        {t("total")}: {clienteles.length}
                    </Box>
                </TableContainer>
                {selectedClienteles.length > 0 && (
                    <Grid container spacing={2} className={classes.btnGroupStyles}>
                        <Grid item xs={6}>
                            <Button color="primary" onClick={handleAbort}>
                                {t("clear-selection")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    <StatusDropdown
                                        name={"state"}
                                        control={control}
                                    ></StatusDropdown>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" type="submit"
                                            disabled={watch("state") === "" as DetailedPlanningState}>
                                        {t("apply")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </form>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    tableContainerStyles: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },

    container: {
        display: "flex",
        flexFlow: "column",
        height: "calc(100vh - 292px)",
    },

    withButtons: {
        height: "calc(100vh - 348px)",
    },

    headerStyles: {
        textTransform: "capitalize",
        paddingTop: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: "white",
    },

    CircularProgressStyles: {
        display: "block",
        margin: "2rem auto 2rem",
    },

    btnGroupStyles: {
        padding: "8px",
    },

    totalBox: {
        position: "absolute",
        padding: "2px",
        background: "white",
        border: "1px solid lightgrey",
        bottom: 0,
        right: "20px",
        fontSize: "10px",
    },
    totalSpacing: {
        bottom: "56px",
    },
}));

export default ClientelesTable;
