import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DetailedPlanningDetailsSideNav from "./DetailedPlanningDetailsSideNav";
import { useLocation } from "react-router-dom";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Article,
  ArticleAssignment,
  autoplaceDetailedPlanning,
  DetailedPlanningDetail,
  DetailedPlanningDetailPage,
  DetailedPlanningPosition,
  DetailedPlanningState,
  findOrCreateDetailedPlanning,
  getPreviousAvpPrice,
  MarketingCampaign,
  PreviousAvpPriceResult,
  setExportFile,
  updateArticleAssignments,
  UpdateArticleAssignmentsPayload,
  UpdatePositionPayload, upsertState,
} from "../../api/detailedPlanningApiClient";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import AssignmentsTable from "../detailed-planning/components/AssignmentsTable";
import PrintTemplateAndStatus from "../detailed-planning/components/PrintTemplateAndStatus";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import DroppableTab from "../detailed-planning/components/DroppableTab";
import { UnlistedArticleProps } from "../detailed-planning/components/UnlistedArticle";
import { InsertionArticle } from "../detailed-planning/components/ArticleRow";
import ConfirmDialog from "../../components/ConfirmDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { EXPORT_TYPE } from "../../api/basedataApiClient";
import ReactRouterPrompt from "react-router-prompt";

interface DetailedPlanningLayoutProps {
  children: React.ReactNode;
  params?: {
    clientele: string;
    year: string;
    weekOfYear: string;
    importVersion: string;
  } | undefined;
}

export interface FormValues {
  pages: {
    positions: DetailedPlanningPosition[];
  }[];
  pagesToCopy: string[];
}

export function checkProperties(obj: any) {
  for (var key in obj) {
    if (obj[key] === null || obj[key] === "") return false;
  }
  return true;
}

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
}

const DetailedPlanningDetailsLayout = ({
  children,
  params,
}: DetailedPlanningLayoutProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [detailedPlanning, setDetailedPlanning] =
    useState<DetailedPlanningDetail>();
  const [activeTab, setActiveTab] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [lastAddedArticlePosition, setLastAddedArticlePosition] =
    useState<number>();
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: useMemo(
      () => ({
        pages: detailedPlanning?.pages.map((page) => ({
          positions: [...Array(page.maxPositions)].map((position, index) => {
            const fields =
              page?.positions?.filter((a) => {
                return a.articleAssignments[0]?.position === index;
              }) || [];
            const fieldsHistory =
                // eslint-disable-next-line array-callback-return
                page?.positions?.find((a) => {
                  if (a.articleHistoryAssignments)
                  return a.articleHistoryAssignments[0]?.position === index;
                });
            return {
              articleAssignments:
                fields.length >= 1 ? fields[0].articleAssignments : [],
              linkedPositions:
                fields.length >= 1 ? fields[0].linkedPositions : [],
              articleHistoryAssignments:
              fieldsHistory ? fieldsHistory.articleHistoryAssignments : [],
            };
          }),
        })),
      }),
      [detailedPlanning]
    ),
  });

  const { reset, watch, control, setValue } = methods;
  const { dirtyFields } = useFormState({
    control,
  });
  const watchedPages = watch(`pages`) || [];
  const formValues = watch();

  let location = useLocation();

  const { clientele, importVersion, weekOfYear, year } =
    (location.state as LocationProp)
      ? location.state
      : JSON.parse(localStorage.getItem("detailedPlaning") as string);

  const getDetailedPlanning = useCallback(async () => {
    try {
      const result = await findOrCreateDetailedPlanning({
        year: parseInt(year),
        weekOfYear: parseInt(weekOfYear),
        version: parseInt(importVersion),
        clientele: clientele,
      });
      setDetailedPlanning(result);
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [clientele, importVersion, weekOfYear, year, enqueueSnackbar]);

  const setAutoplaceDetailedPlanning = async () => {
    try {
      setLoadingImport(true);
      const result = await autoplaceDetailedPlanning({
        year: parseInt(year),
        weekOfYear: parseInt(weekOfYear),
        version: parseInt(importVersion),
        clientele: clientele,
      });
      setDetailedPlanning(result);
      setLoadingImport(false);
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setShowImportDialog(false);
    }
  };

  const setExport = async (exportType: EXPORT_TYPE) => {
    try {
      setLoadingExport(true);
      let result;
      if (exportType === EXPORT_TYPE.EXPORTADPLAN.toString()) {
        result = await setExportFile(
          {
            year: parseInt(year),
          },
          exportType
        );
      } else {
        result = await setExportFile(
          {
            year: parseInt(year),
            weekOfYear: parseInt(weekOfYear),
            version: parseInt(importVersion),
            clientele: clientele,
          },
          exportType
        );
      }
      setLoadingExport(false);
      enqueueSnackbar(t("export-successfully-started"), {
        variant: "success",
      });

      if (exportType === EXPORT_TYPE.EXPORTPDF_MAILTO) {
        const subject = t("export-pdf-mail-subject", {
          year: year,
          weekOfYear: weekOfYear,
          clientele: clientele,
        });
        const bodyText = t("export-pdf-mail-body", {
          year: year,
          weekOfYear: weekOfYear,
          clientele: clientele,
          link: result.link,
        });
        // DETBGADS-306 - never forget to encode the subject and body text as they are passed as url parameters:
        const encodedSubject = encodeURIComponent(subject);
        const encodedBodyText = encodeURIComponent(bodyText);
        window.open(`mailto:?subject=${encodedSubject}&body=${encodedBodyText}`);
      }

      return result;
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDetailedPlanning();
  }, [getDetailedPlanning]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLastAddedArticlePosition(undefined);
    setActiveTab(newValue);
  };

  const tabChange = (newValue: number) => {
    setLastAddedArticlePosition(undefined);
    setActiveTab(newValue);
  };

  const handleTabDropUnlisted = (item: UnlistedArticleProps, index: number) => {
    addArticleToFlyerPage({
      selectedTab: index,
      unlistedArticleName: item.unlistedArticleName,
    });
  };

  const handleTabDropArticle = (item: InsertionArticle, index: number) => {
    addArticleToFlyerPage({
      selectedTab: index,
      article: item.article,
      marketingCampaign: item.marketingCampaign,
      insertionPosition: item.position || undefined,
    });
  };

  const handleTabDropAssigned = (item: ArticleAssignment[], index: number) => {
    addArticleToFlyerPage({
      selectedTab: index,
      assignedArticle: item,
    });
  };

  const getAvpPriceResult = useCallback(
    (articleId: number) => {
      const result = getPreviousAvpPrice({
        year: parseInt(year),
        weekOfYear: parseInt(weekOfYear),
        clientele: clientele,
        articleId: articleId,
      });
      return result;
    },
    [clientele, weekOfYear, year]
  );

  const addArticleToFlyerPage = useCallback(
    ({
      marketingCampaign,
      article,
      insertionPosition,
      unlistedArticleName,
      addAsSubposition,
      assignedArticle,
      selectedTab,
    }: {
      marketingCampaign?: MarketingCampaign | null;
      article?: Article;
      insertionPosition?: { positionId: number; positionIndex: number };
      unlistedArticleName?: string;
      addAsSubposition?: boolean;
      assignedArticle?: ArticleAssignment[];
      selectedTab?: number;
    }) => {
      const formValues = watch();
      const tabIndex: number | undefined = Number.isInteger(selectedTab) ? selectedTab : activeTab;

      if(tabIndex !== undefined){
        const linkedPositions = formValues?.pages[tabIndex].positions
          .flatMap((p) => p.linkedPositions)
          .filter(Boolean);
        const freePositions =
          formValues?.pages[tabIndex].positions
            ?.map((pos, index) => ({
              index: index,
              articleAssignments: pos.articleAssignments,
              linkedPositions: pos.linkedPositions,
            }))
            .filter(
              (pos) =>
                !(
                  pos.articleAssignments?.length ||
                  linkedPositions.includes(pos.index)
                )
            )
            .map((pos) => pos.index) || [];

        if (freePositions.length >= 1 || addAsSubposition) {
          (async () => {
            let avpPrice;
            if (article?.articleId) {
              avpPrice = (await getAvpPriceResult(
                article?.articleId as number
              )) as PreviousAvpPriceResult;
            }

            const updatePosition =
              addAsSubposition && lastAddedArticlePosition !== undefined
                ? lastAddedArticlePosition
                : freePositions[0];
            let assignments: ArticleAssignment[] = assignedArticle || [
              {
                adPlanningPositionId: insertionPosition?.positionId || null,
                position: updatePosition,
                marketingCampaignId:
                  marketingCampaign?.marketingCampaignId || null,
                articleId: article?.articleId || null,
                articleRange: false,
                avpPrice: avpPrice ? avpPrice.avpPrice : 0,
                avpWeek: avpPrice?.weekOfYear,
                avpYear: avpPrice?.year,
                lvpPrice: avpPrice ? avpPrice.lvpPrice : 0,
                eyeCatcher: "",
                processingNote: "",
                graphicNote: "",
                poster: false,
                paperSize: null,
                coverPage: article?.coverPage || undefined,
                hero: article?.hero || undefined,
                tailorMade: article?.tailorMade || undefined,
                onlineCampaign: article?.onlineCampaign || undefined,
                offerDiscount: article?.offerDiscount || undefined,
                unlistedArticleName:
                  unlistedArticleName || article?.articleName || null,
                customQuality: article?.customQuality || undefined,
                customQualityDescription:
                  article?.customQualityDescription || undefined,
                customQuality2: article?.customQuality2 || undefined,
                customQualityDescription2:
                  article?.customQualityDescription2 || undefined,
                customQuality3: article?.customQuality3 || undefined,
                customQualityDescription3:
                  article?.customQualityDescription3 || undefined,
                customQuality4: article?.customQuality4 || undefined,
                customQualityDescription4:
                  article?.customQualityDescription4 || undefined,
              },
            ];
            let newPages = [...formValues.pages];
            let newDetailedPlanning = { ...detailedPlanning };

            const newPagesFiltered = detailedPlanning
              ? newPages.map((page, index) => ({
                  name: detailedPlanning.pages[index].name,
                  maxPositions: detailedPlanning.pages[index].maxPositions,
                  positions: page.positions.filter((pos) =>
                    pos.hasOwnProperty("articleAssignments")
                  ),
                }))
              : [];
            newDetailedPlanning.pages = newPagesFiltered;

            const assignedCopy = JSON.parse(JSON.stringify(assignments));
            const newAssignments = assignedCopy.map(
              (assignment: ArticleAssignment) => {
                assignment.position = updatePosition;
                return assignment;
              }
            );
            if (newDetailedPlanning.pages) {
              // add to selected page
              let pageTo = newDetailedPlanning.pages[tabIndex];
              if (pageTo.hasOwnProperty("positions")) {
                if (pageTo.positions) {
                  const positionToUpdate =
                      pageTo.positions?.filter(
                          (pos) => pos.articleAssignments![0]?.position === updatePosition
                      ) || [];
                  if (positionToUpdate[0]) {
                    pageTo.positions = pageTo.positions?.map((position) => {
                      if (position === positionToUpdate[0]) {
                        return {
                          ...position,
                          articleAssignments: [
                            ...position.articleAssignments,
                            ...assignments,
                          ],
                        };
                      } else {
                        return position;
                      }
                    });
                  } else {
                    pageTo.positions.push({
                      articleAssignments: [...newAssignments],
                      linkedPositions: [],
                    });
                  }
                }
              }
              // delete from actual page
              if (assignedArticle) {
                if (
                  Number.isInteger(selectedTab) &&
                  Number.isInteger(assignedArticle[0].position)
                ) {
                  let pageFrom = newDetailedPlanning.pages[activeTab];
                  if (pageFrom.hasOwnProperty("positions")) {
                    if (pageFrom.positions) {
                      pageFrom.positions = pageFrom.positions?.filter(
                          (position) =>
                              position.articleAssignments![0]?.position !==
                              assignedArticle![0].position
                      );
                    }
                  }
                }
              }
              if (newDetailedPlanning)
                setDetailedPlanning(
                  newDetailedPlanning as DetailedPlanningDetail
                );
            }
            setFieldsChanged(true);
            setLastAddedArticlePosition(updatePosition);
          })();
        } else {
          enqueueSnackbar(t("no-free-position-on-page"), {
            variant: "error",
          });
        }
      }
    },
    [
      getAvpPriceResult,
      detailedPlanning,
      enqueueSnackbar,
      lastAddedArticlePosition,
      t,
      watch,
      activeTab,
    ]
  );
  const onSubmit = async (pageIndex: number) => {
    setLoadingSubmit(true);
    reset(formValues);
    let payload: UpdateArticleAssignmentsPayload = {
      positions: formValues.pages.map(
        (page) =>
          page.positions
            ?.filter(
              (p: DetailedPlanningPosition) =>
                p.articleAssignments && p.articleAssignments.length > 0
            )
            .map<UpdatePositionPayload>((position) => ({
              articleAssignments: position.articleAssignments,
              linkedPositions: position.linkedPositions || [],
            })) || []
      ),
    };
    try {
      await updateArticleAssignments(
        {
          year: parseInt(year),
          weekOfYear: parseInt(weekOfYear),
          version: parseInt(importVersion),
          clientele: clientele,
        },
        payload
      );
      await getDetailedPlanning();
      enqueueSnackbar(t("successfully-applied"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("error"), { variant: "error" });
    }
    if (
      watchedPages
        .flatMap((p) => p.positions?.flatMap((a) => a?.articleAssignments))
        .filter(Boolean).length > 0 &&
      detailedPlanning?.state === ("UNKNOWN" as any)
    ) {
      try {
        let result = await upsertState(
            {
              year: parseInt(year),
              weekOfYear: parseInt(weekOfYear),
              version: parseInt(importVersion),
              clientele: clientele,
            },
            {
              state: DetailedPlanningState.IN_PROGRESS,
            }
        );
        result = {
          ...result,
          pages: result.pages.map((r: any, i: number) => {
            return {
              ...r,
              positions: watchedPages[i]?.positions?.filter(
                (p) => p.articleAssignments
              ),
            };
          }),
        };
        if (detailedPlanning) {
          setDetailedPlanning(result);
        }
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
    setLoadingSubmit(false);
  };

  let path = location.pathname.split("/");
  path.pop();

  // use this for ReactRouterPrompt ?
  // const navigateTo = useCallback(
  //   (path: string) => {
  //     navigate(path, {
  //       state: location.state,
  //     });
  //   },
  //   [navigate, location]
  // );

  const allreadyInUse =
    watchedPages.length !== 0
      ? watchedPages
          .flatMap((p) => p.positions?.flatMap((a) => a?.articleAssignments))
          .filter(Boolean).length > 0
      : null;
  useEffect(() => {
    const pages = {
      pages: detailedPlanning?.pages.map((page) => ({
        positions: [...Array(page.maxPositions)].map((position, index) => {
          const fields =
            page?.positions?.filter((a) => {
              return a.articleAssignments[0]?.position === index;
            }) || [];
          const fieldsHistory =
              // eslint-disable-next-line array-callback-return
              page?.positions?.find((a) => {
                if (a.articleHistoryAssignments)
                return a.articleHistoryAssignments[0]?.position === index;
              });
          return {
            articleAssignments:
              fields.length >= 1 ? fields[0].articleAssignments : [],
            linkedPositions:
              fields.length >= 1 ? fields[0].linkedPositions : [],
            articleHistoryAssignments:
                fieldsHistory ? fieldsHistory.articleHistoryAssignments : [],
          };
        }),
      })),
    };
    (async () => {
      await reset(pages);
      // if (pages.pages) setValue(`pages`, pages.pages);
    })();
  }, [detailedPlanning, reset, setValue]);
  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xs={4}
          style={{ height: "inherit", overflow: "auto", zIndex: 1 }}
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box marginBottom={1}>
              <DetailedPlanningDetailsSideNav
                clientele={clientele}
                lastAddedArticlePosition={lastAddedArticlePosition}
                importVersion={importVersion}
                weekOfYear={weekOfYear}
                year={year}
                assigned={
                  formValues.pages
                    ?.flatMap(
                      (p) =>
                        p.positions?.flatMap((a) => a.articleAssignments) || []
                    )
                    .filter(Boolean) || []
                }
                addArticleToFlyerPage={addArticleToFlyerPage}
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            height: "inherit",
            overflow: "auto",
            paddingLeft: "10px",
            zIndex: 0,
          }}
        >
          <Card variant="outlined">
            {detailedPlanning && detailedPlanning.pages && (
              <CardContent
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Box>
                      <Typography variant="h6" gutterBottom component="div">
                        {t("calender-week")} {weekOfYear}
                        {detailedPlanning.lastModifiedBy && (
                          <Tooltip
                            arrow
                            onMouseEnter={() => {
                              timeout = setTimeout(
                                () => setTooltipIsOpen(true),
                                100
                              );
                            }}
                            onMouseLeave={() => {
                              clearTimeout(timeout);
                              setTooltipIsOpen(false);
                            }}
                            open={tooltipIsOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <>
                                <b>{t("editor")}:</b>
                                <br />
                                {detailedPlanning.lastModifiedBy}
                              </>
                            }
                          >
                            <InfoOutlinedIcon
                              color="primary"
                              style={{
                                fontSize: "18px",
                                margin: "0px 5px -2px 10px",
                              }}
                            />
                          </Tooltip>
                        )}
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        {year}
                      </Typography>
                    </Box>

                    <PrintTemplateAndStatus
                      params={params}
                      watchedPages={watchedPages}
                      setFieldsChanged={setFieldsChanged}
                      detailedPlanning={detailedPlanning}
                      value={{
                        printTemplateId: detailedPlanning.sourceTemplateId || 0,
                        state:
                          detailedPlanning.state ||
                          DetailedPlanningState.UNKNOWN,
                      }}
                      onChange={(value) => {
                        setDetailedPlanning(value.detailedPlanningDetail);
                      }}
                      allreadyInUse={allreadyInUse}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        {detailedPlanning?.pages.map(
                          (item: DetailedPlanningDetailPage, index: number) => (
                            <DroppableTab
                              key={"tab-" + index}
                              index={index}
                              item={item}
                              tabChange={tabChange}
                              handleTabDropUnlisted={handleTabDropUnlisted}
                              handleTabDropArticle={handleTabDropArticle}
                              handleTabDropAssigned={handleTabDropAssigned}
                            />
                          )
                        )}
                      </Tabs>
                    </Box>
                    <FormProvider {...methods}>
                      <Box flexGrow={1}>
                        {detailedPlanning.pages.map((page, index) => (
                          <div
                            key={`tabpanel-${index}`}
                            role="tabpanel"
                            hidden={activeTab !== index}
                            id={`tabpanel-${index}`}
                            aria-labelledby={`tab-${index}`}
                            style={{ height: "100%" }}
                          >
                            <AssignmentsTable
                              setDetailedPlanning={setDetailedPlanning}
                              detailedPlanning={detailedPlanning}
                              sourceTemplateName={
                                detailedPlanning.sourceTemplateName
                              }
                              sourceTemplatePages={detailedPlanning.pages.map(
                                (page, index) =>
                                  page.name ? page.name : (index + 1).toString()
                              )}
                              setLastAddedArticlePosition={
                                setLastAddedArticlePosition
                              }
                              lastAddedArticlePosition={
                                lastAddedArticlePosition
                              }
                              setFieldsChanged={setFieldsChanged}
                              fieldsChanged={fieldsChanged}
                              getAvpPriceResult={getAvpPriceResult}
                              addArticleToFlyerPage={addArticleToFlyerPage}
                              key={`assignmentstable-${index}`}
                              assignedPositions={page.positions || []}
                              //getExportSheets={getExportSheets}
                              setExport={setExport}
                              loadingExport={loadingExport}
                              maxPositions={page.maxPositions}
                              onSubmit={() => onSubmit(index)}
                              onReset={() => {
                                getDetailedPlanning();
                              }}
                              pageIndex={index}
                              setShowImportDialog={setShowImportDialog}
                              loadingSubmit={loadingSubmit}
                              state={detailedPlanning.state}
                            />
                          </div>
                        ))}
                      </Box>
                      <ConfirmDialog
                        title={t("auto-import")}
                        open={showImportDialog}
                        message={
                          allreadyInUse
                            ? t("auto-import-warning-message")
                            : t("auto-import-message")
                        }
                        handleConfirm={setAutoplaceDetailedPlanning}
                        handleReject={() => setShowImportDialog(false)}
                        ariaLabel="print-template-warning-title"
                        ariaDescription="print-template-warning-message"
                        confirmBtnText={t("import")}
                        loading={loadingImport}
                      />
                      <ReactRouterPrompt when={dirtyFields.pages !== undefined || fieldsChanged}>
                        {({ isActive, onConfirm, onCancel }) => (
                            <ConfirmDialog
                                title={t("leave-detailed-planning")}
                                message={t("leave-detailed-planning-dialog")}
                                open={isActive}
                                handleReject={onCancel}
                                confirmBtnText={t("leave")}
                                handleConfirm={onConfirm}
                            />
                        )}
                      </ReactRouterPrompt>
                    </FormProvider>
                  </Box>
                </>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailedPlanningDetailsLayout;
