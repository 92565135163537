import { AppBar, Box, makeStyles, Toolbar } from "@material-ui/core";
import Logo from "./Logo";
import SignOutButton from "../login/SignOutButton";
import UploadButton from "../login/UploadButton";
import { PermissionGuard } from "../../components/PermissionGuard";
import {
  Badge,
  CircularProgress,
  ClickAwayListener,
  Popper,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import {
  DownloadDTO,
  EXPORT_STATE,
  getExportFile,
  getNotifications,
} from "../../api/basedataApiClient";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import useInterval from "../../hooks/useInterval";
import SecurityUtil from "../../helper/securityUtil";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBarStyles: {
    padding: theme.spacing(0),
  },
  popoverSpan: {
    position: "relative",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    opacity: "1",
    "&:hover": {
      cursor: "pointer",
      background: "lightgrey",
    },
    "&.disabled": {
      cursor: "pointer",
      background: "lightgrey",
    },
  },
  popoverSpanDisabled: {
    position: "relative",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    opacity: "0.4",
    "&:hover": {
      cursor: "unset",
      background: "lightgrey",
    },
  },
  popper: {
    border: `1px solid #e1e4e8`,
    boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
    borderRadius: 2,
    width: "auto",
    zIndex: theme.zIndex.modal,
    backgroundColor: "#fff",
  },
  clearIcon: {
    margin: "0 0 0 auto",
    width: "20px",
    opacity: "0.5",
    "&:hover": {
      opacity: "1",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationData, setNotificationData] = useState<DownloadDTO[]>([]);
  const [notificationDataCount, setNotificationDataCount] = useState<number>(0);
  useInterval(async () => {
    const notifications = await getNotifications();
    const intersection = notifications.filter(
      (a) =>
        false ===
        notificationData.some(
          (b) => a.filename === b.filename || a.exportState === b.exportState
        )
    );
    if (intersection.length > 0 && notificationDataCount === 0) {
      setNotificationDataCount(intersection.length);
    }
    setNotificationData(notifications);
  }, 5000);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (setNotificationDataCount) setNotificationDataCount(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getTooltipTitle = (index: number) => {
    const campaign = notificationData[index].campaignId
      ? `${t("campaign")}-ID: ${t(
          notificationData[index].campaignId.toString()
        )} `
      : "";
    const period = notificationData[index].weekofyear
      ? `${t("period")}: ${t(notificationData[index].weekofyear.toString())} `
      : "";
    const year = notificationData[index].year
      ? `${t("year")}: ${t(notificationData[index].year.toString())} `
      : "";
    const mediaTyoe = notificationData[index].mediaType
      ? `${t("media-type")}: ${t(notificationData[index].mediaType)} `
      : "";
    const clientele = notificationData[index].clientele
      ? `${t("clientele")}: ${t(notificationData[index].clientele)} `
      : "";
    return period + year + mediaTyoe + clientele + campaign;
  };

  const getExport = async (
    d: DownloadDTO,
    index: number,
    preventDownload = false
  ) => {
    try {
      const result = await getExportFile(
        { ...d, fileName: t(d.filename) },
        preventDownload
      );
      if (setNotificationData)
        setNotificationData(
          notificationData.filter((item, itemIndex) => itemIndex !== index)
        );
      return result;
    } catch (e) {
      const error = e as Error;
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={1} className={classes.appBar}>
        <Toolbar className={classes.toolBarStyles}>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          {notificationData.length !== 0 && (
            <Box sx={{ mr: 4 }}>
              <Badge
                style={{ cursor: "pointer" }}
                aria-describedby={id}
                badgeContent={notificationDataCount}
                color="primary"
                onClick={handleClick}
              >
                <DownloadIcon style={{ fontSize: "30px" }} color="inherit" />
              </Badge>
              <Popper
                className={classes.popper}
                id={"notifications-" + id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    {notificationData.map((d, index) => (
                      <Tooltip
                        arrow
                        placement="right"
                        title={getTooltipTitle(index)}
                      >
                        <span
                          onClick={() => {
                            if (d.exportState === EXPORT_STATE.FINISHED)
                              getExport(d, index);
                          }}
                          className={
                            d.exportState === EXPORT_STATE.FINISHED
                              ? classes.popoverSpan
                              : classes.popoverSpanDisabled
                          }
                          key={index + d.exportType}
                        >
                          {t(d.exportType)}
                          <DownloadIcon
                            style={{ margin: "0 0 0 10px" }}
                            color="primary"
                          />
                          <ClearIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              if (d.exportState === EXPORT_STATE.FINISHED)
                                getExport(d, index, true);
                            }}
                            className={classes.clearIcon}
                            color="error"
                          />
                          {d.exportState !== EXPORT_STATE.FINISHED && (
                            <CircularProgress
                              style={{
                                position: "absolute",
                                left: "0",
                                right: "0",
                                margin: "auto",
                                width: "20px",
                                height: "20px",
                                top: "0",
                                bottom: "0",
                              }}
                            />
                          )}
                        </span>
                      </Tooltip>
                    ))}
                  </div>
                </ClickAwayListener>
              </Popper>
            </Box>
          )}
          <Box sx={{ mr: 2 }}>
            <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
              <UploadButton />
            </PermissionGuard>
          </Box>
          <Box sx={{ mr: 2 }}>
            <SignOutButton />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
