import Keycloak from "keycloak-js";

const keycloakServerUrl =
  process.env.REACT_APP_KEYCLOAK_SERVER_URL ||
  document.documentElement.dataset.keycloakServerUrl ||
  "";
const keycloakRealm =
  process.env.REACT_APP_KEYCLOAK_REALM ||
  document.documentElement.dataset.keycloakRealm ||
  "";
const keycloakClientId =
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
  document.documentElement.dataset.keycloakClientId ||
  "";

const keycloak = new Keycloak({
  url: keycloakServerUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId
});

export default keycloak;
