import CustomerCollapsibleTableRow from "./CustomerCollapsibleTableRow";
import {Customers} from "../../../api/detailedPlanningApiClient";

interface CustomerCollapsibleTableProps {
    customers: Customers[];
    year: number;
    weekOfYear: number;
    clientele: string;
    importVersion: number;
    status: string;
}

const CustomerCollapsibleTable = ({
                                      customers,
                                      year,
                                      weekOfYear,
                                      clientele,
                                      importVersion,
                                      status,
                                  }: CustomerCollapsibleTableProps) => {
    return (
        <>
            <div>
                {customers.map((item) => {
                    return (
                        <CustomerCollapsibleTableRow
                            key={item.id}
                            customer={item}
                            year={year}
                            weekOfYear={weekOfYear}
                            clientele={clientele}
                            importVersion={importVersion}
                            status={status}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default CustomerCollapsibleTable;
