import i18n from "../i18n";

import ClientelePage from "../pages/detailed-planning/ClientelePage";
import CustomerPage from "../pages/detailed-planning/CustomerPage";
import DetailedPlanningPage from "../pages/detailed-planning/DetailedPlanningPage";

const DetailedPlanningForClientsRoutes = [
  {
    path: "/" + i18n.t("detailed-planning-for-clients").toLowerCase(),
    element: <DetailedPlanningPage/>,
  },
  {
    path:
      "/" +
      i18n.t("detailed-planning-for-clients").toLowerCase() +
      "/clientele",
    element: <ClientelePage/>,
  },
  {
    path:
      "/" +
      i18n.t("detailed-planning-for-clients").toLowerCase() +
      "/clientele/:customerId",
    element: <CustomerPage/>,
  },
];

export default DetailedPlanningForClientsRoutes;
