import { TableRow, Tooltip } from "@material-ui/core";
import { ReactElement, RefObject, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  ArticleAssignment,
  ArticleHistoryAssignment,
  DetailedPlanningPosition,
  PreviousAvpPriceResult,
} from "../../../api/detailedPlanningApiClient";
import { FormValues } from "../../layout/DetailedPlanningDetailsLayout";
import { InsertionArticle } from "./ArticleRow";
import FilledRow from "./FilledRow";
import { UnlistedArticleProps } from "./UnlistedArticle";
import { ConnectableElement, useDrag } from "react-dnd";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface NestedAssignmentsProps {
  setLastAddedArticlePosition: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  lastAddedArticlePosition: number | undefined;
  pageIndex: number;
  positionIndex: number;
  selectedFieldIndex: string | null;
  setSelectedFieldIndex: (value: string | null) => void;
  getAvpPriceResult: (articleId: number) => void;
  setFieldsChanged: (value: boolean) => void;
  handleUnlistedDrop: (item: UnlistedArticleProps, index: number) => void;
  handleDrop: (
    insertionArticle: InsertionArticle,
    positionIndex: number,
    avpPriceResult: PreviousAvpPriceResult | undefined,
  ) => void;
  handleDragEnd: (dragIndex: number, hoverIndex: number) => void;
  assignmentIndex: number;
  field: ArticleAssignment;
  verticalRef: RefObject<HTMLDivElement>;
  drop: (
    elementOrNode: ConnectableElement,
    options?: any,
  ) => ReactElement | null;
  handlerId: string | symbol | null;
  isActiveDrop: { isOver: boolean; canDrop: boolean };
  articleHistoryAssignment: ArticleHistoryAssignment | null;
}

const NestedAssignments = ({
  pageIndex,
  positionIndex,
  selectedFieldIndex,
  lastAddedArticlePosition,
  setLastAddedArticlePosition,
  setSelectedFieldIndex,
  setFieldsChanged,
  getAvpPriceResult,
  handleUnlistedDrop,
  handleDrop,
  assignmentIndex,
  field,
  verticalRef,
  drop,
  handlerId,
  isActiveDrop,
  articleHistoryAssignment,
}: NestedAssignmentsProps) => {
  const { control, setValue, getValues } = useFormContext<FormValues>();

  let timeout: ReturnType<typeof setTimeout>;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const { t } = useTranslation();

  useFieldArray({
    name: `pages.${pageIndex}.positions.${positionIndex}.articleAssignments`,
    control: control,
  });

  const removeAssignment = (index: number, articleIndex: number) => {
    setFieldsChanged(true);
    setSelectedFieldIndex(null);
    const formValues = getValues();
    const newAssignments = [
      ...formValues.pages[pageIndex].positions[index].articleAssignments,
    ];
    newAssignments.splice(articleIndex, 1);
    setValue(
      `pages.${pageIndex}.positions.${index}.articleAssignments`,
      newAssignments,
    );
    setValue(`pages.${pageIndex}.positions.${index}.linkedPositions`, []);
    if (positionIndex === lastAddedArticlePosition) {
      setLastAddedArticlePosition(undefined);
    }
  };

  // eslint-disable-next-line
  const [isDragging, drag] = useDrag<DetailedPlanningPosition>({
    type: "assignedArticle",
    item: () => {
      const formValues = getValues();
      return {
        index: positionIndex,
        articleAssignments: [
          ...formValues.pages[pageIndex].positions[positionIndex]
            .articleAssignments,
        ],
        linkedPositions: [],
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (assignmentIndex === 0) drag(drop(verticalRef));

  return (
    <>
      <TableRow
        key={"position-" + positionIndex + "-assigmentrow" + assignmentIndex}
        onClick={() => {
          selectedFieldIndex ===
          "assignment-" + positionIndex + "-" + assignmentIndex
            ? setSelectedFieldIndex(null)
            : setSelectedFieldIndex(
                "assignment-" + positionIndex + "-" + assignmentIndex,
              );
        }}
        style={{
          backgroundColor:
            selectedFieldIndex ===
            "assignment-" + positionIndex + "-" + assignmentIndex
              ? "rgba(244, 244, 244, 1)"
              : articleHistoryAssignment?.externalAdded
                ? "rgba(255, 0, 0, 0.18)"
                : undefined,
          display: "table-row",
          position: "relative",
        }}
      >
        <FilledRow
          isActiveDrop={isActiveDrop}
          verticalRef={verticalRef}
          handlerId={handlerId}
          key={"position-" + positionIndex + "-assignment-" + assignmentIndex}
          index={positionIndex}
          articleIndex={assignmentIndex}
          article={field}
          pageIndex={pageIndex}
          fieldRowName={`pages.${pageIndex}.positions.${positionIndex}`}
          handleDrop={handleDrop}
          getAvpPriceResult={getAvpPriceResult}
          handleUnlistedDrop={handleUnlistedDrop}
          removeAssignment={removeAssignment}
          selectedFieldIndex={null}
          articleHistoryAssignment={articleHistoryAssignment}
        />
        {articleHistoryAssignment && articleHistoryAssignment.externalAdded && (
          <Tooltip
            arrow
            onMouseEnter={() => {
              timeout = setTimeout(() => setTooltipIsOpen(true), 100);
            }}
            onMouseLeave={() => {
              clearTimeout(timeout);
              setTooltipIsOpen(false);
            }}
            open={tooltipIsOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <>
                {articleHistoryAssignment.unlistedArticleName ||
                articleHistoryAssignment.articleName ? (
                  <div>
                    <b>
                      {t("external-changed") + ", " + t("original-article")}:
                    </b>
                    <br />
                    {`${
                      articleHistoryAssignment.unlistedArticleName ||
                      articleHistoryAssignment.articleName
                    } - `}
                    {articleHistoryAssignment.eyeCatcher
                      ? ` ${t("eye-catcher")}: "${articleHistoryAssignment.eyeCatcher}"`
                      : ""}
                    {articleHistoryAssignment.articleRange
                      ? ` ${t("article-range")}: ${articleHistoryAssignment.articleRange}`
                      : ""}
                    {articleHistoryAssignment.lvpPrice
                      ? ` ${t("price")}(lvp): ${articleHistoryAssignment.lvpPrice / 100} €`
                      : ""}
                    {articleHistoryAssignment.avpPrice
                      ? ` ${t("price")}(avp): ${articleHistoryAssignment.avpPrice / 100} €`
                      : ""}
                    {articleHistoryAssignment.processingNote
                      ? ` ${t("handling-note")}: "${articleHistoryAssignment.processingNote}"`
                      : ""}
                  </div>
                ) : (
                  <div>
                    <b>
                      {t("external-changed") + ", " + t("original-article")}:
                    </b>
                    <br /> {t("empty-position")}
                  </div>
                )}
              </>
            }
          >
            <InfoOutlinedIcon
              color="primary"
              style={{
                fontSize: "18px",
                margin: "0px 5px -2px 10px",
                position: "absolute",
                right: "0",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          </Tooltip>
        )}
      </TableRow>
    </>
  );
};
export default NestedAssignments;
