import { Button, ButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

const SignOutButton = (props: ButtonProps) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  if (keycloak.authenticated) {
    return (
      <Button
        {...props}
        type="submit"
        variant="text"
        color="secondary"
        onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
      >
        {t("sign-out")}
      </Button>
    );
  } else {
    return <></>;
  }
};
export default SignOutButton;
