import {Controller} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import {DropDownItem} from "../../../api/basedataApiClient";
import useLocalStorage from "../../../hooks/useLocalStorage";

interface GenericSelectProps {
    name: string;
    label?: string;
    control: any;
    onChange?: () => void;
    onBlur?: () => void;
    options?: DropDownItem[];
    showEmptyOption?: boolean;
    localStorage?: boolean;
    disabled?: boolean;
}

const GenericSelect = ({
                           name,
                           label,
                           control,
                           options,
                           onChange,
                           showEmptyOption = true,
                           localStorage = false,
                           disabled,
                           onBlur,
                       }: GenericSelectProps) => {
    const {t} = useTranslation();
    const [actualValue, setActualValue] = useLocalStorage<string>(
        `tbg-select-${name}`,
        ""
    );

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => {
                return (
                    <TextField
                        select
                        id="select"
                        value={actualValue || field.value || ""}
                        onChange={(e) => {
                            field.onChange(parseInt(e.target.value) || e.target.value);
                            if (localStorage) {
                                setActualValue(e.target.value);
                            }
                            return onChange ? onChange() : null;
                        }}
                        label={label}
                        variant="outlined"
                        size="small"
                        onBlur={onBlur}
                        InputLabelProps={{shrink: true}}
                        fullWidth
                        disabled={disabled}
                    >
                        {showEmptyOption && (
                            <MenuItem value="">
                                <em>{t("empty-option")}</em>
                            </MenuItem>
                        )}

                        {options &&
                            options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                );
            }}
        />
    );
};

export default GenericSelect;
