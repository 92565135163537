import {Box, Button, Typography, makeStyles} from "@material-ui/core";

import {useTranslation} from "react-i18next";

import ContentCard from "../../../components/ContentCard";
import PrintingTabs from "../components/PrintingTabs";
import {Link, useLocation} from "react-router-dom";

interface ClienteleProps {
    year: string;
    weekOfYear: string;
    clientele: string;
    importVersion: string;
    status: string;
}

const navlinkStyle = {
    color: "white",
    textDecoration: "none",
    width: "100%",
};

const Clientele = ({
                       year,
                       weekOfYear,
                       clientele,
                       importVersion,
                       status,
                   }: ClienteleProps) => {
    const {t} = useTranslation();
    const classes = useStyles();
    let location = useLocation();
    const base = location.pathname.split("/")[1];
    return (
        <Box>
            <ContentCard className={classes.container}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                >
                    <Typography variant="h6" gutterBottom component="div">
                        {clientele}
                    </Typography>
                    <Button variant="contained" color="primary">
                        <Link
                            style={navlinkStyle}
                            to={"/" + base + "/details"}
                            state={{
                                year: year,
                                weekOfYear: weekOfYear,
                                importVersion: importVersion,
                                clientele: clientele,
                            }}
                        >
                            {t("for-detailed-planning")}
                        </Link>
                    </Button>
                </Box>

                <PrintingTabs
                    year={year}
                    weekOfYear={weekOfYear}
                    clientele={clientele}
                    importVersion={importVersion}
                    distributeInformationDisabled={true}
                    status={status}
                />
            </ContentCard>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(100vh - 99px)",
    },

    subtitleStyles: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    boxHeight: {
        height: theme.spacing(2),
    },

    textFieldStyles: {
        width: "100%",
    },
}));

export default Clientele;
