import { Grid, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Clientele,
  DetailedPlanningState,
  getPaperSizes,
  updatePrintingHouseClientele,
} from "../../../api/detailedPlanningApiClient";
import GenericTextField from "./GenericTextField";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { updatePrintingHouseCustomer } from "../../../api/detailedPlanningApiClient";
import { useSnackbar } from "notistack";
import GenericSelect from "../dropdowns/GenericSelect";
import { DropDownItem } from "../../../dto/Adplan";
import { makeStyles } from "@material-ui/core";
import HistoryTooltip from "./HistoryTooltip";
import * as React from "react";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../../../helper/securityUtil";

interface PrintingInformationProps {
  fieldsReadOnly?: boolean;
  clienteleResult: Clientele;
  clientele: string | undefined;
  customerId?: string;
  importVersion: string;
  fetchDetails: () => void;
  status: string;
}

const Item = styled(Paper)(({ theme }: any) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface FormValues {
  printingHouseName: string;
  printCirculation: string;
  customerCirculation: string;
  totalCirculation: string;
  numberOfPoster: string;
  paperSize: string | null;
  implementationNote: string;
  printCirculationHistory?: string;
  customerCirculationHistory?: string;
  implementationNoteHistory?: string;
}

const defaultValues = {
  printingHouseName: "",
  printCirculation: "",
  customerCirculation: "",
  totalCirculation: "",
  numberOfPoster: "",
  paperSize: null,
  implementationNote: "",
  printCirculationHistory: undefined,
  customerCirculationHistory: undefined,
  implementationNoteHistory: undefined,
};

const useStyles = makeStyles((theme) => ({
  hasHistory: {
    background: "rgba(255, 0, 0, 0.18)!important",
    display: "flex",
    position: "relative",
  },
}));

const PrintingInformation = ({
  fieldsReadOnly,
  clienteleResult,
  customerId,
  clientele,
  importVersion,
  fetchDetails,
  status,
}: PrintingInformationProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const { control, watch, setValue, getValues, formState, reset } =
    useForm<FormValues>({
      defaultValues,
    });
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [paperSizes, setPaperSizes] = useState<DropDownItem[]>();
  const getId = (str: string) => {
    var id = str.replace(/[^0-9]/g, "");
    return +id;
  };

  const fetchPaperSizes = useCallback(async (mounted: boolean) => {
    try {
      const paperSizes = await getPaperSizes();
      if (mounted) {
        setPaperSizes(paperSizes);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const setPrintingHouseDetails = async () => {
    if (customerId) {
      try {
        await updatePrintingHouseCustomer(
          {
            version: parseInt(importVersion),
            year: clienteleResult.year,
            weekOfYear: clienteleResult.weekOfYear,
            customerId: +customerId,
            clientele: clienteleResult.clientele,
          },
          "POST",
          {
            printingHouseName: getValues().printingHouseName ?? "",
            printCirculation: getId(getValues().printCirculation),
            customerCirculation: getId(getValues().customerCirculation),
            numberOfPoster: getId(getValues().numberOfPoster),
            paperSize: getValues().paperSize,
            implementationNote: getValues().implementationNote,
          },
        );
        fetchDetails();
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    } else if (clientele) {
      try {
        await updatePrintingHouseClientele(
          {
            version: parseInt(importVersion),
            year: clienteleResult.year,
            weekOfYear: clienteleResult.weekOfYear,
            clientele: clienteleResult.clientele,
          },
          "POST",
          {
            printingHouseName: getValues().printingHouseName ?? "",
            printCirculation: getId(getValues().printCirculation),
            customerCirculation: getId(getValues().customerCirculation),
            numberOfPoster: getId(getValues().numberOfPoster),
            paperSize: getValues().paperSize,
            implementationNote: getValues().implementationNote,
          },
        );
        fetchDetails();
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }

    let totalCirculation =
      getId(getValues().printCirculation) +
      getId(getValues().customerCirculation);

    setValue("totalCirculation", `${totalCirculation} Stück`);
    reset(getValues());
  };

  // useEffect(() => {
  //   const subscription = watch(() => {
  //     formState.isDirty = true;
  //   });
  //
  //   return () => subscription.unsubscribe();
  // }, [watch, formState]);

  const checkDisabled = () => {
    let disabled = true;
    if (!fieldsReadOnly) {
      if (
        status !== DetailedPlanningState.IN_PROGRESS.toString() &&
        SecurityUtil.isMarktbetreiber(keycloak)
      ) {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    return disabled;
  };

  useEffect(() => {
    setValue("printingHouseName", clienteleResult.printingHouseName);
    setValue(
      "printCirculation",
      (clienteleResult.printCirculation?.toString() || "0") + " Stück",
    );
    setValue(
      "customerCirculation",
      (clienteleResult.customerCirculation?.toString() || "0") + " Stück",
    );
    setValue(
      "totalCirculation",
      (clienteleResult.totalCirculation?.toString() || "0") + " Stück",
    );
    setValue(
      "numberOfPoster",
      (clienteleResult.numberOfPoster === null
        ? "0"
        : clienteleResult.numberOfPoster.toString()) + " Stück",
    );
    if (clienteleResult.paperSizes) {
      setValue("paperSize", clienteleResult.paperSizes.split(",")[0]);
    } else {
      setValue(
        "paperSize",
        clienteleResult.paperSize ? clienteleResult.paperSize : null,
      );
    }
    setValue("implementationNote", clienteleResult.implementationNote);
    setValue(
      "printCirculationHistory",
      typeof clienteleResult.printCirculationHistory !== "undefined"
        ? clienteleResult.printCirculationHistory?.toString() + " Stück"
        : undefined,
    );
    setValue(
      "customerCirculationHistory",
      typeof clienteleResult.customerCirculationHistory !== "undefined"
        ? clienteleResult.customerCirculationHistory?.toString() + " Stück"
        : undefined,
    );
    setValue(
      "implementationNoteHistory",
      typeof clienteleResult.implementationNoteHistory !== "undefined"
        ? clienteleResult.implementationNoteHistory?.toString() + " Stück"
        : undefined,
    );
  }, [clienteleResult, setValue]);

  useEffect(() => {
    let mounted = true;
    fetchPaperSizes(mounted);
    return () => {
      mounted = false;
    };
  }, [fetchPaperSizes]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <GenericTextField
          name="printingHouseName"
          label={t("printing-house")}
          control={control}
          onBlur={setPrintingHouseDetails}
          disabled={
            !fieldsReadOnly ||
            SecurityUtil.isMarktbetreiber(keycloak)
          }
        />
      </Box>

      <Box height={"16px"} />

      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <Item
            elevation={0}
            style={{ paddingLeft: "0px" }}
            className={
              typeof getValues().printCirculationHistory !== "undefined" &&
              getValues().printCirculationHistory !==
                getValues().printCirculation
                ? classes.hasHistory
                : ""
            }
          >
            <GenericTextField
              name="printCirculation"
              label={t("distribution")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={checkDisabled()}
            />
            <HistoryTooltip
              printingHouseHistory={
                typeof getValues().printCirculationHistory !== "undefined" &&
                getValues().printCirculationHistory !==
                  getValues().printCirculation
                  ? getValues().printCirculationHistory
                  : ""
              }
            />
          </Item>
        </Grid>
        <span style={{ fontSize: "16px" }}>+</span>
        <Grid item xs>
          <Item
            elevation={0}
            className={
              typeof getValues().customerCirculationHistory !== "undefined" &&
              getValues().customerCirculationHistory !==
                getValues().customerCirculation
                ? classes.hasHistory
                : ""
            }
          >
            <GenericTextField
              name="customerCirculation"
              label={t("homework")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={checkDisabled()}
            />
            <HistoryTooltip
              printingHouseHistory={
                typeof getValues().customerCirculationHistory !== "undefined" &&
                getValues().customerCirculationHistory !==
                  getValues().customerCirculation
                  ? getValues().customerCirculationHistory
                  : ""
              }
            />
          </Item>
        </Grid>
        <span style={{ fontSize: "16px" }}>=</span>

        <Grid item xs>
          <Item elevation={0} style={{ paddingRight: "0px" }}>
            <GenericTextField
              name="totalCirculation"
              label={t("total-circulation")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={checkDisabled()}
            />
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <Item elevation={0} style={{ paddingLeft: "0px" }}>
            <GenericTextField
              name="numberOfPoster"
              label={t("number-of-poster")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={
                fieldsReadOnly ||
                SecurityUtil.isMarktbetreiber(keycloak)
              }
            />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item elevation={0}>
            {paperSizes && (
              <GenericSelect
                name={"paperSize"}
                label={t("poster-dimensions")}
                control={control}
                options={paperSizes}
                disabled={
                  fieldsReadOnly ||
                  SecurityUtil.isMarktbetreiber(keycloak)
                }
                onBlur={setPrintingHouseDetails}
              />
            )}
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <Item
            className={
              typeof getValues().implementationNoteHistory !== "undefined" &&
              getValues().implementationNoteHistory !==
                getValues().implementationNote
                ? classes.hasHistory
                : ""
            }
            elevation={0}
            style={{ paddingLeft: "0px" }}
          >
            <GenericTextField
              name="implementationNote"
              multiline={true}
              label={t("implementation-note")}
              control={control}
              onBlur={setPrintingHouseDetails}
              disabled={checkDisabled()}
            />
            <HistoryTooltip
              printingHouseHistory={
                typeof getValues().implementationNoteHistory !== "undefined" &&
                getValues().implementationNoteHistory !==
                  getValues().implementationNote
                  ? getValues().implementationNoteHistory
                  : ""
              }
            />
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default PrintingInformation;
