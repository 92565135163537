import SearchInput from "./SearchInput";
import { Box, CircularProgress } from "@material-ui/core";
import InsertionsTable from "../detailed-planning/components/InsertionsTable";
import { useState, useEffect, useCallback } from "react";
import {
  Article,
  ArticleAssignment,
  insertionfromadplanning,
  MarketingCampaign,
  InsertionFromAdplanningResult,
} from "../../api/detailedPlanningApiClient";

export interface RenderCampaign {
  positionId: number;
  positionIndex: number;
  marketingCampaign: MarketingCampaign;
  insertionFromAdplanningResult: InsertionFromAdplanningResult;
  renderArticles?: Article[];
  initiallyOpen: boolean;
  articlesAssigned: boolean;
}
interface InsertionsProps {
  clientele: string;
  importVersion: string;
  weekOfYear: string;
  year: string;
  assigned: ArticleAssignment[];
  lastAddedArticlePosition: number | undefined;
  addArticleToFlyerPage: (params: {
    marketingCampaign?: MarketingCampaign | null;
    article?: Article;
    insertionPosition?: { positionId: number; positionIndex: number };
    unlistedArticleName?: string;
    addAsSubposition?: boolean;
  }) => void;
}

const Insertions = ({
  clientele,
  importVersion,
  weekOfYear,
  year,
  assigned,
  lastAddedArticlePosition,
  addArticleToFlyerPage,
}: InsertionsProps) => {
  const [insertionFromAdplanningResult, setInsertionFromAdplanningResult] =
    useState<InsertionFromAdplanningResult>();

  const [fileredRenderCampaigns, setFileredRenderCampaigns] =
    useState<RenderCampaign[]>();

  const [searchedVal, setSearchedVal] = useState("");

  const fetchInsertionFromAdplanning = useCallback(async () => {
    let result = await insertionfromadplanning({
      clientele: clientele,
      importVersion: +importVersion,
      year: +year,
      weekOfYear: +weekOfYear,
    });
    setInsertionFromAdplanningResult(result);
  }, [clientele, importVersion, weekOfYear, year]);

  useEffect(() => {
    fetchInsertionFromAdplanning();
  }, [fetchInsertionFromAdplanning]);

  useEffect(() => {
    const renderCampaigns = insertionFromAdplanningResult
      ? insertionFromAdplanningResult.marketingCampaigns
          .flatMap((item) =>
            item.positions.flatMap((position) => {
              const assignedPositionIds = assigned
                .filter(
                  (assignment) =>
                    assignment.adPlanningPositionId === position.positionId &&
                    assignment.marketingCampaignId === item.marketingCampaignId
                )
                .map((filtered) => filtered.adPlanningPositionId);

              const articlesByBrands = Object.keys(
                position.articlesByBrand
              ).map((brand) => position.articlesByBrand[brand]);

              const articlesAssigned = assignedPositionIds.includes(
                position.positionId
              );

              //  --> to render campaigns without assigned articles, use this:
              // const renderArticles = !assignedPositionIds.includes(
              //   position.positionId
              // )
              //   ? position.mandatoryArticles.length
              //     ? position.mandatoryArticles
              //     : articlesByBrands.flat()
              //   : [];

              const renderArticles = position.mandatoryArticles.length
                ? position.mandatoryArticles
                : articlesByBrands.flat();

              return {
                insertionFromAdplanningResult: {
                  clientele: insertionFromAdplanningResult.clientele,
                  importVersion: insertionFromAdplanningResult.importVersion,
                  weekOfYear: insertionFromAdplanningResult.weekOfYear,
                  year: insertionFromAdplanningResult.year,
                  marketingCampaigns: [],
                },
                marketingCampaign: {
                  name: item.name,
                  marketingCampaignId: item.marketingCampaignId,
                  positions: [],
                },
                positionId: position.positionId,
                positionIndex: position.positionIndex,
                renderArticles: renderArticles,
                initiallyOpen: false,
                articlesAssigned: articlesAssigned,
              };
            })
          )
          .sort(
            (a, b) =>
              b.renderArticles
                .map((a) => a.numberOfSalesMarkets)
                .reduce((acc, val) => acc + val, 0) -
              a.renderArticles
                .map((a) => a.numberOfSalesMarkets)
                .reduce((acc, val) => acc + val, 0)
          )
      : undefined;

    if (searchedVal) {
      if (searchedVal.length > 1 && renderCampaigns) {
        const filteredByCampaignsResults = renderCampaigns.filter((campaigns) =>
          campaigns.marketingCampaign.name
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        );

        const filteredByArticlesResults = renderCampaigns
          .map((campaign) => {
            return {
              ...campaign,
              initiallyOpen: false,
              renderArticles: campaign.renderArticles.filter((article) =>
                article.articleName
                  .toLowerCase()
                  .includes(searchedVal.toLowerCase())
              ),
            };
          })
          .filter(function (obj) {
            return obj.renderArticles.length !== 0;
          });

        const ids = new Set(
          filteredByCampaignsResults.map(
            (d) => d.marketingCampaign.marketingCampaignId
          )
        );

        const merged = [
          ...filteredByCampaignsResults,
          ...filteredByArticlesResults.filter(
            (d) => !ids.has(d.marketingCampaign.marketingCampaignId)
          ),
        ];

        setFileredRenderCampaigns(merged);
      } else if (renderCampaigns) {
        setFileredRenderCampaigns(renderCampaigns);
      }
    } else {
      setFileredRenderCampaigns(renderCampaigns);
    }
  }, [
    assigned,
    insertionFromAdplanningResult,
    addArticleToFlyerPage,
    searchedVal,
  ]);

  return (
    <>
      <Box display="flex" flexDirection="column" ml={2} mr={2} mb={2}>
        <SearchInput requestSearch={setSearchedVal} />
      </Box>

      {insertionFromAdplanningResult && (
        <InsertionsTable
          lastAddedArticlePosition={lastAddedArticlePosition}
          assigned={assigned}
          renderCampaigns={fileredRenderCampaigns || null}
          addArticleToFlyerPage={addArticleToFlyerPage}
        />
      )}
      {fileredRenderCampaigns === undefined && (
        <div
          style={{
            height: "5em",
            padding: "1em",
          }}
        >
          <CircularProgress
            style={{
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Insertions;
