import { callApi } from "./callApi";
import {
  DropDownResult,
  DropDownItem,
  EXPORT_TYPE,
} from "../api/basedataApiClient";

export interface ClientelesParams {
  year: number;
  weekOfYear?: (number | undefined)[];
  salesLineId?: (number | undefined)[];
  salesDistrictId?: (number | undefined)[];
  status?: (string | undefined)[];
  externalChanged?: boolean;
  sourceTemplateName?: string;
}

export interface ClientelesResult {
  clienteles: Clienteles[];
}

export interface Clienteles {
  status: string;
  year: number;
  weekOfYear: number;
  clientele: string;
  version: number;
  sourceTemplateName: string;
  customers: Customers[];
  dateFrom: string;
  dateTo: string;
  externalChanged: boolean;
}

export interface Customers {
  id: number;
  name: string;
}

export interface ClienteleParams {
  version?: number;
  year: number;
  weekOfYear?: number;
  clientele?: string;
  customerId?: number;
}

export interface Clientele {
  importVersion: number;
  year: number;
  weekOfYear: number;
  clientele: string;
  printingHouseName: string;
  printCirculation: number;
  customerCirculation: number;
  totalCirculation: number;
  customerId?: number;
  numberOfPoster: number;
  paperSize?: string | null;
  paperSizes?: string | null;
  implementationNote: string;
  printCirculationHistory?: string;
  customerCirculationHistory?: string;
  implementationNoteHistory?: string;
}

export interface DistributionCustomer {
  importVersion: number;
  year: number;
  weekOfYear: number;
  clientele: string;
  customerId: number;
  distributionAgency: string;
  publishingHouse: string;
  distributionMedium: string;
  edition: string;
  dayOfDelivery: string;
  supplierDetails: string;
  comment: string;
  supplierAddress1: string;
  supplierName1: string;
  supplierZipCode1: string;
  supplierCity1: string;
  supplierStreetAddress1: string;
  supplierAddress2: string;
  supplierName2: string;
  supplierZipCode2: string;
  supplierCity2: string;
  supplierStreetAddress2: string;
  distributionAgencyAddress: string;
  distributionAgencyEmail: string;
  supplierCirculation1: number;
  supplierCirculation2: number;
}

export interface PrintingHouseCustomerPayload {
  printingHouseName: string;
  printCirculation: number;
  customerCirculation: number;
  numberOfPoster: number;
  paperSize: string | null;
  implementationNote: string;
}

export interface DistributionCustomerPayload {
  distributionAgency: string;
  publishingHouse: string;
  distributionMedium: string;
  edition: string;
  dayOfDelivery: string;
  supplierDetails: string;
  comment: string;
  supplierAddress1: string;
  supplierName1: string;
  supplierZipCode1: string;
  supplierCity1: string;
  supplierStreetAddress1: string;
  supplierAddress2: string;
  supplierName2: string;
  supplierZipCode2: string;
  supplierCity2: string;
  supplierStreetAddress2: string;
  distributionAgencyAddress: string;
  distributionAgencyEmail: string;
  supplierCirculation1: number;
  supplierCirculation2: number;
}

export interface InsertionFromAdplanningParams {
  clientele: string;
  importVersion: number;
  weekOfYear: number;
  year: number;
}

export interface InsertionFromAdplanningResult {
  clientele: string;
  importVersion: number;
  weekOfYear: number;
  year: number;
  marketingCampaigns: MarketingCampaign[];
}

export interface MarketingCampaign {
  name: string;
  marketingCampaignId: number;
  positions: Position[];
}

export interface ArticleSalesfiguresResult {
  articleId: number;
  sumSalesFigures: number;
  averageSalesFigures: number;
  customerCount: number;
}

export interface StringResult {
  value: string | null;
}

export interface Position {
  articlesByBrand: Record<string, Article[]>;
  mandatoryArticles: Article[];
  positionId: number;
  positionIndex: number;
  renderArticles?: Article[];
}

export interface Article {
  articleId: number;
  articleName: string;
  coverPage: boolean;
  hero: boolean;
  tailorMade: boolean;
  onlineCampaign: boolean;
  offerDiscount: boolean;
  customQuality: boolean;
  customQualityDescription: string;
  numberOfSalesMarkets: number;
  totalNumberOfSalesMarkets: number;
  obligationRange: boolean;
  customQuality2: boolean;
  customQualityDescription2: string;
  customQuality3: boolean;
  customQualityDescription3: string;
  customQuality4: boolean;
  customQualityDescription4: string;
  customQuality5: boolean;
  customQualityDescription5: string;
  customQuality6: boolean;
  customQualityDescription6: string;
  actConversion: number;
  nomConversion: number;
}

export interface ArticleSalesfiguresParams {
  clientele: string;
  articleId: number;
  version: number;
}

export interface AllArticlesResult {
  articlesByBrand: any;
  totalElements: number;
  totalPages: number;
  number: number;
  numberOfElements: number;
}

export interface AllArticlesParams {
  clientele: string;
  page: number;
  limit: number;
  search?: string;
  version: number;
}

export interface PrintTemplateResults {
  results: PrintTemplate[];
  total: number;
  size: number;
}

export interface PrintTemplate {
  id: number;
  externalId: number;
  name: string;
  pages: PrintTemplatePage[];
}

export interface PrintTemplatePage {
  maxPositions: number;
  name: string;
}
export interface FindDetailedPlanningParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
}

export interface AutoplacePlanningParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
}

export interface CopyDetailedPlanningParams {
  version: number;
  year: number;
  weekOfYear: number;
  fromClientele: string;
  toClientele: string;
  pages: string[];
}
export interface DetailedPlanningDetail {
  id: number;
  importVersion: number;
  year: number;
  weekOfYear: number;
  clientele: string;
  sourceTemplateId: number;
  sourceTemplateName: string;
  lastModifiedBy: string;
  state: DetailedPlanningState;
  pages: DetailedPlanningDetailPage[];
}
export interface DetailedPlanningDetailPage {
  name: string;
  maxPositions: number;
  positions: DetailedPlanningPosition[] | null;
}

export interface DetailedPlanningPosition {
  articleAssignments: ArticleAssignment[];
  articleHistoryAssignments?: ArticleHistoryAssignment[];
  linkedPositions: number[];
  id?: string;
  index?: number;
}

export enum DetailedPlanningState {
  UNKNOWN = "UNKNOWN",
  IN_GRAPHIC = "IN_GRAPHIC",
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_RELEASE = "IN_RELEASE",
  CRITICAL = "CRITICAL",
  READY = "READY",
}

export interface UpsertPrintTemplateParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
  force?: boolean;
}

export interface UpsertStateParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
}

export interface UpsertStatePayload {
  state: DetailedPlanningState;
}

export interface UpsertPrintTemplatePayload {
  printTemplateId: number;
}

export interface UpsertClienteleDaysParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
  force?: boolean;
}

export interface UpsertClienteleDaysPayload {
  dateFrom: string;
  dateTo: string;
}

export interface UpsertClienteleStatesPayload {
  year: number;
  weekOfYear: number;
  version: number;
  clientele: string;
  state: DetailedPlanningState;
}

export interface UpdateArticleAssignmentsParams {
  version: number;
  year: number;
  weekOfYear: number;
  clientele: string;
}

export interface ArticleAssignment {
  position: number | null;
  marketingCampaignId: number | null;
  articleId: number | null;
  unlistedArticleName: string | null;
  eyeCatcher: string;
  articleRange: boolean;
  lvpPrice: number;
  avpPrice: number;
  avpWeek?: number | null;
  avpYear?: number | null;
  adPlanningPositionId: number | null;
  processingNote: string;
  graphicNote: string;
  poster: boolean;
  paperSize: string | null;
  coverPage?: boolean;
  hero?: boolean;
  tailorMade?: boolean;
  onlineCampaign?: boolean;
  offerDiscount?: boolean;
  customQuality?: boolean;
  customQualityDescription?: string;
  numberOfSalesMarkets?: number;
  totalNumberOfSalesMarkets?: number;
  obligationRange?: boolean;
  customQuality2?: boolean;
  customQualityDescription2?: string;
  customQuality3?: boolean;
  customQualityDescription3?: string;
  customQuality4?: boolean;
  customQualityDescription4?: string;
  customQuality5?: boolean;
  customQualityDescription5?: string;
  customQuality6?: boolean;
  customQualityDescription6?: string;
  autoPlaced?: boolean;
  actConversion?: number;
  nomConversion?: number;
}

export interface ArticleHistoryAssignment {
  position: number | null;
  subPosition: number | null;
  articleName: string | null;
  unlistedArticleName: string | null;
  eyeCatcher: string;
  articleRange: boolean;
  avpPrice: number;
  lvpPrice: number;
  processingNote: string;
  externalAdded: boolean;
}

export interface PreviousAvpPricePayload {
  year: number;
  weekOfYear: number;
  clientele: string;
  articleId: number;
}

export interface PreviousAvpPriceResult {
  year: number;
  weekOfYear: number;
  avpPrice: number;
  lvpPrice: number;
}

export interface UpdateArticleAssignmentsPayload {
  positions: UpdatePositionPayload[][];
}

export interface UpdatePositionPayload {
  articleAssignments: ArticleAssignment[];
  linkedPositions: number[];
}

export function getYears(): Promise<number[]> {
  return callApi("/api/ui/detailedplanning/filter/years", "GET");
}

export function getWeeksOfYear(year: string): Promise<DropDownItem[]> {
  return callApi(
    `/api/ui/detailedplanning/filter/weeksofyear?year=${year}`,
    "GET",
  );
}

export function getSalesline(): Promise<DropDownResult> {
  return callApi("/api/ui/salesline", "GET");
}

export function getSalesDistrict(): Promise<DropDownResult> {
  return callApi("/api/ui/salesdistrict", "GET");
}

export function getPaperSizes(): Promise<DropDownItem[]> {
  return callApi("/api/ui/detailedplanning/papersizes", "GET");
}

export function getClienteles(
  params: ClientelesParams,
): Promise<ClientelesResult> {
  let queryStr = `/api/ui/detailedplanning/clienteles?year=${params.year}`;
  if (params.weekOfYear) queryStr += `&weekOfYear=${params.weekOfYear}`;
  if (params.salesLineId) queryStr += `&salesLineId=${params.salesLineId}`;
  if (params.salesDistrictId)
    queryStr += `&salesDistrictId=${params.salesDistrictId}`;
  if (params.status) queryStr += `&status=${params.status}`;
  if (params.sourceTemplateName)
    queryStr += `&sourceTemplateName=${params.sourceTemplateName}`;
  if (params.externalChanged)
    queryStr += `&externalChanged=${params.externalChanged}`;
  return callApi(queryStr, "GET");
}

export function getClientele(params: ClienteleParams): Promise<Clientele> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/printinghouse/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/clientele?clientele=${clientele}`,
    "GET",
  );
}

export function getCustomer(params: ClienteleParams): Promise<Clientele> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/printinghouse/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/customer?clientele=${clientele}&customerId=${params.customerId}`,
    "GET",
  );
}

export function getExportClienteleIntern(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/clienteleintern?clientele=${clientele}`,
    "POST",
  );
}

export function getExportClienteleExtern(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/clienteleextern?clientele=${clientele}`,
    "POST",
  );
}

export function getExportKassenexport(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/kassenexport?clientele=${clientele}`,
    "POST",
  );
}
export function getExportDruckversand(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/druckversand?clientele=${clientele}`,
    "POST",
  );
}

export function getExportClienteleInternAll(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/clienteleinternall?clientele=${clientele}`,
    "POST",
  );
}

export function getExportClienteleExternAll(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/clienteleexternall?clientele=${clientele}`,
    "POST",
  );
}

export function getExportKassenexportAll(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/kassenexportall?clientele=${clientele}`,
    "POST",
  );
}

export function getExportKassenexportWeek(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/kassenexportweek?clientele=${clientele}`,
    "POST",
  );
}

export function getExportDruckversandWeek(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/druckversandweek?clientele=${clientele}`,
    "POST",
  );
}

export function getExportDruckversandAll(
  params: ClienteleParams,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/export/druckversandall?clientele=${clientele}`,
    "POST",
  );
}

export function getExportInsertions(params: ClienteleParams): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/insertionsexport?clientele=${clientele}`,
    "POST",
  );
}

export function setExportFile(
  params: ClienteleParams,
  exportType: EXPORT_TYPE,
): Promise<string> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning${
      params.version ? "/version/" + params.version : ""
    }${params.year ? "/year/" + params.year : ""}${
      params.weekOfYear ? "/weekofyear/" + params.weekOfYear : ""
    }/export/${exportType.toLowerCase()}${
      clientele ? "?clientele=" + clientele : ""
    }`,
    "POST",
  );
}

export function updatePrintingHouseCustomer(
  params: ClienteleParams,
  method: string,
  payload?: PrintingHouseCustomerPayload,
) {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/printinghouse/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/customer?clientele=${clientele}&customerId=${params.customerId}`,
    method,
    payload,
  );
}

export function updatePrintingHouseClientele(
  params: ClienteleParams,
  method: string,
  payload?: PrintingHouseCustomerPayload,
) {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/printinghouse/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/clientele?clientele=${clientele}`,
    method,
    payload,
  );
}

export function updateDistributionCustomer(
  params: ClienteleParams,
  method: string,
  payload: DistributionCustomerPayload,
) {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/distribution/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/customer?clientele=${clientele}&customerId=${params.customerId}`,
    method,
    payload,
  );
}

export function getDistributionCustomer(
  params: ClienteleParams,
): Promise<DistributionCustomer> {
  const clientele = params.clientele
    ? encodeURIComponent(params.clientele)
    : "";
  return callApi(
    `/api/ui/detailedplanning/distribution/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/customer?clientele=${clientele}&customerId=${params.customerId}`,
    "GET",
  );
}

export function insertionfromadplanning(
  params: InsertionFromAdplanningParams,
): Promise<InsertionFromAdplanningResult> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/insertionfromadplanning?clientele=${clientele}&importVersion=${params.importVersion}&weekOfYear=${params.weekOfYear}&year=${params.year}`,
    "GET",
  );
}

export function getArticleSalesfigures(
  params: ArticleSalesfiguresParams,
): Promise<ArticleSalesfiguresResult> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/insertionfromadplanning/version/${params.version}/article/${params.articleId}/salesfigures?clientele=${clientele}`,
    "GET",
  );
}

export function getAdvertisingCommitmentComment(
  marketingCampaignId: number,
): Promise<StringResult> {
  return callApi(
    `/api/ui/detailedplanning/insertionfromadplanning/campaign/${marketingCampaignId}/advertisingcommitmentcomment`,
    "GET",
  );
}

export function allArticles(
  params: AllArticlesParams,
): Promise<AllArticlesResult> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/allarticles/version/${params.version}?clientele=${clientele}&page=${params.page}&limit=${params.limit}&search=${params.search}`,
    "GET",
  );
}

export function getPrintTemplates(): Promise<PrintTemplateResults> {
  return callApi(`/api/ui/detailedplanning/printtemplates`, "GET");
}

export function findOrCreateDetailedPlanning(
  params: FindDetailedPlanningParams,
): Promise<DetailedPlanningDetail> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}?clientele=${clientele}`,
    "GET",
  );
}

export function autoplaceDetailedPlanning(
  params: AutoplacePlanningParams,
): Promise<DetailedPlanningDetail> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/autoplace?clientele=${clientele}`,
    "GET",
  );
}

export function copyDetailedPlanning(
  params: CopyDetailedPlanningParams,
): Promise<DetailedPlanningDetail> {
  const fromClientele = encodeURIComponent(params.fromClientele);
  const toClientele = encodeURIComponent(params.toClientele);
  return callApi(
    `/api/ui/detailedplanning/copy/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}?fromClientele=${fromClientele}&toClientele=${toClientele}&pages=${params.pages}`,
    "GET",
  );
}

export function upsertPrintTemplate(
  params: UpsertPrintTemplateParams,
  payload: UpsertPrintTemplatePayload,
): Promise<DetailedPlanningDetail> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${
      params.year
    }/weekofyear/${
      params.weekOfYear
    }/printtemplate?clientele=${clientele}&force=${params.force || false}`,
    "POST",
    payload,
  );
}

export function upsertState(
  params: UpsertStateParams,
  payload: UpsertStatePayload,
): Promise<DetailedPlanningDetail> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/state?clientele=${clientele}`,
    "POST",
    payload,
  );
}

export function upsertClienteleStates(
  payload: UpsertClienteleStatesPayload[],
): Promise<void> {
  return callApi(`/api/ui/detailedplanning/states`, "POST", payload);
}

export function upsertClienteleDays(
  params: UpsertClienteleDaysParams,
  payload: UpsertClienteleDaysPayload,
): Promise<void> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${
      params.year
    }/weekofyear/${params.weekOfYear}/days?clientele=${clientele}&force=${
      params.force || false
    }`,
    "POST",
    payload,
  );
}

export function updateArticleAssignments(
  params: UpdateArticleAssignmentsParams,
  payload: UpdateArticleAssignmentsPayload,
): Promise<void> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/version/${params.version}/year/${params.year}/weekofyear/${params.weekOfYear}/assignments?clientele=${clientele}`,
    "POST",
    payload,
  );
}

export function getPreviousAvpPrice(
  params: PreviousAvpPricePayload,
): Promise<PreviousAvpPriceResult> {
  const clientele = encodeURIComponent(params.clientele);
  return callApi(
    `/api/ui/detailedplanning/previousavpprice?year=${params.year}&weekOfYear=${params.weekOfYear}&clientele=${clientele}&articleId=${params.articleId}`,
    "GET",
  );
}
