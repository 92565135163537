import { useTranslation } from "react-i18next";
import { DistributionCustomer } from "../../../api/detailedPlanningApiClient";
import { useForm } from "react-hook-form";
import GenericTextField from "./GenericTextField";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { updateDistributionCustomer } from "../../../api/detailedPlanningApiClient";
import { useSnackbar } from "notistack";
import InputDay from "./InputDay";
import NumberInput from "./NumberInput";

interface DistributeInformationProps {
  distributionCustomer: DistributionCustomer;
  customerId: string;
  importVersion: string;
  fetchDetails: () => void;
}

interface FormValues {
  distributionAgency: string;
  publishingHouse: string;
  distributionMedium: string;
  edition: string;
  dayOfDelivery: string;
  supplierDetails: string;
  comment: string;
  supplierAddress1: string;
  supplierName1: string;
  supplierZipCode1: string;
  supplierCity1: string;
  supplierStreetAddress1: string;
  supplierAddress2: string;
  supplierName2: string;
  supplierZipCode2: string;
  supplierCity2: string;
  supplierStreetAddress2: string;
  distributionAgencyAddress: string;
  distributionAgencyEmail: string;
  supplierCirculation1: number;
  supplierCirculation2: number;
}

const defaultValues = {
  distributionAgency: "",
  publishingHouse: "",
  distributionMedium: "",
  edition: "",
  dayOfDelivery: "",
  supplierDetails: "",
  comment: "",
  supplierAddress1: "",
  supplierName1: "",
  supplierZipCode1: "",
  supplierCity1: "",
  supplierStreetAddress1: "",
  supplierAddress2: "",
  supplierName2: "",
  supplierZipCode2: "",
  supplierCity2: "",
  supplierStreetAddress2: "",
  distributionAgencyAddress: "",
  distributionAgencyEmail: "",
};

const DistributeInformation = ({
  distributionCustomer,
  customerId,
  importVersion,
  fetchDetails,
}: DistributeInformationProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, setValue, getValues, formState, reset } =
    useForm<FormValues>({
      defaultValues,
    });

  // useEffect(() => {
  //   const subscription = watch(() => {
  //     formState.isDirty = true;
  //   });
  //
  //   return () => subscription.unsubscribe();
  // }, [watch, formState]);

  useEffect(() => {
    setValue("distributionAgency", distributionCustomer.distributionAgency);
    setValue("publishingHouse", distributionCustomer.publishingHouse);
    setValue("distributionMedium", distributionCustomer.distributionMedium);
    setValue("edition", distributionCustomer.edition);
    setValue("dayOfDelivery", distributionCustomer.dayOfDelivery);
    setValue("supplierDetails", distributionCustomer.supplierDetails);
    setValue("comment", distributionCustomer.comment);
    setValue("supplierAddress1", distributionCustomer.supplierAddress1);
    setValue("supplierName1", distributionCustomer.supplierName1);
    setValue("supplierZipCode1", distributionCustomer.supplierZipCode1);
    setValue("supplierCity1", distributionCustomer.supplierCity1);
    setValue(
      "supplierStreetAddress1",
      distributionCustomer.supplierStreetAddress1
    );
    setValue("supplierAddress2", distributionCustomer.supplierAddress2);
    setValue("supplierName2", distributionCustomer.supplierName2);
    setValue("supplierZipCode2", distributionCustomer.supplierZipCode2);
    setValue("supplierCity2", distributionCustomer.supplierCity2);
    setValue(
      "supplierStreetAddress2",
      distributionCustomer.supplierStreetAddress2
    );
    setValue(
      "distributionAgencyAddress",
      distributionCustomer.distributionAgencyAddress
    );
    setValue(
      "distributionAgencyEmail",
      distributionCustomer.distributionAgencyEmail
    );
    setValue("supplierCirculation1", distributionCustomer.supplierCirculation1);
    setValue("supplierCirculation2", distributionCustomer.supplierCirculation2);
  }, [distributionCustomer, setValue]);

  const setPrintingHouseDetails = async () => {
    if (customerId) {
      try {
        await updateDistributionCustomer(
          {
            version: parseInt(importVersion),
            year: distributionCustomer.year,
            weekOfYear: distributionCustomer.weekOfYear,
            customerId: +customerId,
            clientele: distributionCustomer.clientele,
          },
          "POST",
          {
            distributionAgency: getValues().distributionAgency,
            publishingHouse: getValues().publishingHouse,
            distributionMedium: getValues().distributionMedium,
            edition: getValues().edition,
            dayOfDelivery: getValues().dayOfDelivery,
            supplierDetails: getValues().supplierDetails,
            comment: getValues().comment,
            supplierAddress1: getValues().supplierAddress1,
            supplierName1: getValues().supplierName1,
            supplierZipCode1: getValues().supplierZipCode1,
            supplierCity1: getValues().supplierCity1,
            supplierStreetAddress1: getValues().supplierStreetAddress1,
            supplierAddress2: getValues().supplierAddress2,
            supplierName2: getValues().supplierName2,
            supplierZipCode2: getValues().supplierZipCode2,
            supplierCity2: getValues().supplierCity2,
            supplierStreetAddress2: getValues().supplierStreetAddress2,
            distributionAgencyAddress: getValues().distributionAgencyAddress,
            distributionAgencyEmail: getValues().distributionAgencyEmail,
            supplierCirculation1: getValues().supplierCirculation1,
            supplierCirculation2: getValues().supplierCirculation2,
          }
        );
        fetchDetails();
        enqueueSnackbar(t("successfully-applied"), { variant: "success" });
      } catch (e) {
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    }
    reset(getValues());
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <GenericTextField
          name="distributionAgency"
          label={t("distribute-agency")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="distributionAgencyAddress"
          label={t("distribute-agency-address")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="distributionAgencyEmail"
          label={t("distribute-agency-email")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="publishingHouse"
          label={t("publisher")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="distributionMedium"
          label={t("distribution-medium")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="edition"
          label={t("output")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <InputDay
          name="dayOfDelivery"
          label={t("delivery-day")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierDetails"
          label={t("delivery-details")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="comment"
          label={t("comment")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierAddress1"
          label={t("delivery-address-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierName1"
          label={t("delivery-name-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierZipCode1"
          label={t("delivery-plz-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierCity1"
          label={t("delivery-city-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierStreetAddress1"
          label={t("delivery-street-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <NumberInput
          name="supplierCirculation1"
          label={t("supplier-circulation-1")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierAddress2"
          label={t("delivery-address-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierName2"
          label={t("delivery-name-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierZipCode2"
          label={t("delivery-plz-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierCity2"
          label={t("delivery-city-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <GenericTextField
          name="supplierStreetAddress2"
          label={t("delivery-street-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />

        <Box height={"16px"} />

        <NumberInput
          name="supplierCirculation2"
          label={t("supplier-circulation-2")}
          control={control}
          onBlur={setPrintingHouseDetails}
        />
      </Box>
    </>
  );
};

export default DistributeInformation;
