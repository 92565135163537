import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Clientele from "../../domain/detailed-planning/views/Clientele";
import { useLocation } from "react-router-dom";

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
  status: string;
}

const ClientelePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as LocationProp;

  useEffect(() => {
    document.title = `${t("detailed-planning")} - Team Beverage`;
  });

  return (
    <>
      {location.state && (
        <Clientele
          year={state.year}
          weekOfYear={state.weekOfYear}
          clientele={state.clientele}
          importVersion={state.importVersion}
          status={state.status}
        />
      )}
    </>
  );
};

export default ClientelePage;
