import {Box, Chip, IconButton, Tooltip} from "@material-ui/core";
import {
    Article,
    getArticleSalesfigures,
    MarketingCampaign,
} from "../../../api/detailedPlanningApiClient";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {useDrag} from "react-dnd";
import AddIcon from "@material-ui/icons/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {PermissionGuard} from "../../../components/PermissionGuard";
import SecurityUtil from "../../../helper/securityUtil";

interface ArticleRowProps {
    marketingCampaign: MarketingCampaign | null;
    article: Article;
    clientele: string;
    importVersion: string;
    lastAddedArticlePosition: number | undefined;
    position?: {
        positionId: number;
        positionIndex: number;
    };
    isMandatory?: true;
    brand?: string;
    addArticleToFlyerPage: (params: {
        marketingCampaign?: MarketingCampaign | null;
        article?: Article;
        insertionPosition?: { positionId: number; positionIndex: number };
        unlistedArticleName?: string;
        addAsSubposition?: boolean;
    }) => void;
    showAddBtn?: boolean;
    dragIndex?: number;
}

export interface InsertionArticle {
    marketingCampaign: MarketingCampaign | null;
    article: Article;
    position: { positionId: number; positionIndex: number } | null;
}

const ArticleRow = ({
                        marketingCampaign,
                        article,
                        position,
                        importVersion,
                        isMandatory,
                        clientele,
                        brand,
                        lastAddedArticlePosition,
                        addArticleToFlyerPage,
                        showAddBtn,
                        dragIndex,
                    }: ArticleRowProps) => {
    const {t} = useTranslation();
    let timeout: ReturnType<typeof setTimeout>;
    // eslint-disable-next-line
    const [collected, drag, dragPreview] = useDrag<InsertionArticle>({
        type: "article",
        item: {
            marketingCampaign: marketingCampaign ? {...marketingCampaign} : null,
            article: {...article},
            position: position ? {...position} : null,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [addBtnOnHover, setAddBtnOnHover] = useState(false);
    const [tooltipContent, setTooltipContent] = useState({
        articleId: article.articleId,
        sumSalesFigures: 0,
        averageSalesFigures: 0,
        customerCount: 0,
    });
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    const getTooltipSalesfigures = async () => {
        const result = await getArticleSalesfigures({
            articleId: article.articleId,
            version: parseInt(importVersion),
            clientele: clientele || "",
        });
        setTooltipContent({
            articleId: result.articleId,
            sumSalesFigures: result.sumSalesFigures,
            averageSalesFigures: result.averageSalesFigures,
            customerCount: result.customerCount,
        });
        setTooltipIsOpen(true);
    };

    return (
        <>
            <TableRow
                ref={drag}
                key={
                    position
                        ? position.positionId +
                        "-" +
                        position.positionIndex +
                        "-" +
                        article.articleId
                        : brand
                            ? brand + "-" + article.articleId
                            : article.articleId
                }
                onMouseEnter={() => setAddBtnOnHover(true)}
                onMouseLeave={() => setAddBtnOnHover(false)}
                sx={{
                    backgroundColor: addBtnOnHover ? "#e3f2fd" : undefined,
                }}
            >
                <TableCell sx={{backgroundColor: "white"}}>
                    <Box
                        pl={3}
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems={"center"}
                    >
                        {addBtnOnHover ? (
                            <Tooltip
                                arrow
                                onMouseEnter={() => {
                                    timeout = setTimeout(() => getTooltipSalesfigures(), 500);
                                }}
                                onMouseLeave={() => {
                                    clearTimeout(timeout);
                                    setTooltipIsOpen(false);
                                }}
                                open={tooltipIsOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "15rem",
                                            }}
                                        >
                      <span style={{flex: "50%"}}>
                        Ø {t("sales-per-market")}
                      </span>
                                            <span style={{flex: "50%"}}>
                        Σ {t("sales-in-the-clientele")}
                      </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                      <span style={{flex: "50%"}}>
                        {tooltipContent.averageSalesFigures === 0
                            ? "---"
                            : tooltipContent.averageSalesFigures}
                      </span>
                                            <span style={{flex: "50%"}}>
                        {tooltipContent.sumSalesFigures === 0
                            ? "---"
                            : tooltipContent.sumSalesFigures}
                      </span>
                                        </div>
                                    </>
                                }
                                placement="right"
                            >
                                <Box>{article.articleName}</Box>
                            </Tooltip>
                        ) : (
                            <Box>{article.articleName}</Box>
                        )}
                        <Box display="flex">
                            {article.coverPage && (
                                <Chip
                                    size="small"
                                    label="TS"
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.hero && (
                                <Chip
                                    size="small"
                                    label="H"
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.tailorMade && (
                                <Chip
                                    size="small"
                                    label="TM"
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.onlineCampaign && (
                                <Chip
                                    size="small"
                                    label="OMA"
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.offerDiscount && (
                                <Chip
                                    size="small"
                                    label="AR"
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality2 && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription2}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality3 && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription3}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality4 && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription4}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality5 && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription5}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}

                            {article.customQuality6 && (
                                <Chip
                                    size="small"
                                    label={article.customQualityDescription6}
                                    color="primary"
                                    style={{fontSize: "12px", padding: 1, marginLeft: 1}}
                                />
                            )}
                        </Box>
                    </Box>
                </TableCell>

                <TableCell sx={{backgroundColor: "white"}}>
                    <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                        <Box pl={0}>
                            {article.numberOfSalesMarkets} von{" "}
                            {article.totalNumberOfSalesMarkets}
                        </Box>
                    </PermissionGuard>
                </TableCell>
                <TableCell
                    sx={{
                        padding: 0,
                        height: 0,
                        backgroundColor: "white",
                    }}
                >

                    <Box
                        style={{width: "100%", height: "100%"}}
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                    >
                        <Box
                            style={{
                                height: "100%",
                            }}
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                                {(!showAddBtn || !addBtnOnHover) && article.obligationRange && (
                                    <CheckCircleOutlineIcon
                                        sx={{marginRight: "12px"}}
                                        color="success"
                                    />
                                )}
                                {(!showAddBtn || !addBtnOnHover) &&
                                    article.obligationRange === null && (
                                        <HelpOutlineIcon
                                            sx={{marginRight: "12px"}}
                                            color="warning"
                                        />
                                    )}
                            </PermissionGuard>
                            {showAddBtn && addBtnOnHover && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        backgroundColor: "white",
                                        height: "100%",
                                        paddingRight: 12,
                                        paddingLeft: 12,
                                    }}
                                >
                                    <DragIndicatorIcon sx={{padding: 0}}/>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        aria-label="add-article"
                                        component="span"
                                        onClick={() => {
                                            addArticleToFlyerPage({
                                                marketingCampaign: marketingCampaign,
                                                article: article,
                                                insertionPosition: position,
                                            });
                                        }}
                                        style={{padding: "0px", marginRight: "4px"}}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                    {lastAddedArticlePosition !== undefined && (
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="add-article"
                                            component="span"
                                            onClick={() => {
                                                addArticleToFlyerPage({
                                                    marketingCampaign: marketingCampaign,
                                                    article: article,
                                                    insertionPosition: position,
                                                    addAsSubposition: true,
                                                });
                                            }}
                                            style={{padding: "0px", marginRight: "4px"}}
                                        >
                                            <PlaylistAddIcon/>
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        </>
    )
        ;
};

export default ArticleRow;
