import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
//@ts-ignore
import {
  DetailedPlanningDetail,
  DetailedPlanningPosition,
  DetailedPlanningState,
  upsertPrintTemplate,
  upsertState,
} from "../../../api/detailedPlanningApiClient";
import ConfirmDialog from "../../../components/ConfirmDialog";
import FlyerDropdown from "../dropdowns/FlyerDropdown";
import StatusDropdown from "../dropdowns/StatusDropdown";
import { PermissionGuard } from "../../../components/PermissionGuard";
import { checkProperties } from "../../layout/DetailedPlanningDetailsLayout";
import SecurityUtil from "../../../helper/securityUtil";

interface UpsertPrintTemplateOrStatePayload {
  printTemplateId: number;
  state: DetailedPlanningState;
}

interface FormValues {
  templateAndState: UpsertPrintTemplateOrStatePayload;
}

interface PrintTemplateAndStatusProps {
  params?: {
    clientele: string;
    year: string;
    weekOfYear: string;
    importVersion: string;
  };
  watchedPages: {
    positions: DetailedPlanningPosition[];
  }[];
  value: UpsertPrintTemplateOrStatePayload;
  onChange: (value: { detailedPlanningDetail: DetailedPlanningDetail }) => void;
  allreadyInUse?: boolean | null;
  setFieldsChanged: Dispatch<SetStateAction<boolean>>;
  detailedPlanning: DetailedPlanningDetail;
}

export interface LocationProp {
  year: string;
  weekOfYear: string;
  importVersion: string;
  clientele: string;
}

const PrintTemplateAndStatus = ({
  params,
  watchedPages,
  onChange,
  value,
  allreadyInUse,
  setFieldsChanged,
  detailedPlanning,
}: PrintTemplateAndStatusProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [showResetDialog, setShowResetDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { clientele, importVersion, weekOfYear, year } =
      params && checkProperties(params)
      ? params
      : (location.state as LocationProp)
      ? (location.state as LocationProp)
      : JSON.parse(localStorage.getItem("detailedPlaning") as string);

  const { control, watch, reset, getValues } = useForm<FormValues>({
    defaultValues: {
      templateAndState: value,
    },
  });
  const handleConfirm = async () => {
    setLoading(true);
    const values = getValues();
    await upsertPrintTemplateCallback(values, true);
    setLoading(false);
    setShowResetDialog(false);
  };

  const handleAlternativeConfirm = async () => {
    setLoading(true);
    const values = getValues();
    await upsertPrintTemplateCallback(values, false);
    setFieldsChanged(true);
    setLoading(false);
    setShowResetDialog(false);
  };

  const handleReject = () => {
    reset({ templateAndState: value });
    setShowResetDialog(false);
  };

  const upsertPrintTemplateCallback = useCallback(
    async (newValue: FormValues, force: boolean = false) => {
      try {
        let result = await upsertPrintTemplate(
          {
            year: parseInt(year),
            weekOfYear: parseInt(weekOfYear),
            version: parseInt(importVersion),
            clientele: clientele,
            force: true,
          },
          {
            printTemplateId: newValue.templateAndState!.printTemplateId,
          }
        );
        if (!force) {
          result = {
            ...result,
            pages: result.pages.map((r, i) => {
              return {
                ...r,
                positions: watchedPages[i]?.positions?.filter(
                  (p) => p.articleAssignments
                ),
              };
            }),
          };
        }
        onChange({ detailedPlanningDetail: result });
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        reset({ templateAndState: value });
      }
    },
    [
      clientele,
      importVersion,
      weekOfYear,
      year,
      enqueueSnackbar,
      onChange,
      reset,
      value,
      watchedPages,
    ]
  );

  const upsertStateCallback = useCallback(
    async (newValue: FormValues) => {
      try {
        await upsertState(
          {
            year: parseInt(year),
            weekOfYear: parseInt(weekOfYear),
            version: parseInt(importVersion),
            clientele: clientele,
          },
          {
            state: newValue.templateAndState!.state,
          }
        );
        let result: DetailedPlanningDetail = {
          ...detailedPlanning,
          pages: detailedPlanning.pages.map((r: any, i: number) => {
            return {
              ...r,
              positions: watchedPages[i]?.positions?.filter(
                (p) => p.articleAssignments
              ),
            };
          }),
        };
        onChange({
          detailedPlanningDetail: {
            ...result,
            state: newValue.templateAndState!.state,
          },
        });
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(error.message, { variant: "error" });
        reset({ templateAndState: value });
      }
    },
    [
      clientele,
      importVersion,
      weekOfYear,
      year,
      enqueueSnackbar,
      reset,
      value,
      detailedPlanning,
      onChange,
      watchedPages,
    ]
  );

  useEffect(() => {
    reset({ templateAndState: value });
    setShowResetDialog(false);
  }, [value, reset]);

  useEffect(() => {
    const subscription = watch(async (newValue, { name, type }) => {
      if (name === "templateAndState.printTemplateId" && type === "change") {
        if (name === "templateAndState.printTemplateId" && allreadyInUse) {
          setShowResetDialog(true);
        } else {
          await upsertPrintTemplateCallback(newValue as any);
        }
      }

      if (name === "templateAndState.state" && type === "change") {
        await upsertStateCallback(newValue as any);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, allreadyInUse, upsertStateCallback, upsertPrintTemplateCallback]);

  return (
    <Box display="flex" alignItems="center">
      <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
        <Box sx={{ width: "200px", mr: 2 }}>
          <FlyerDropdown
            name="templateAndState.printTemplateId"
            control={control}
          />
        </Box>
      </PermissionGuard>
      <Box sx={{ width: "180px" }}>
        <StatusDropdown
          name="templateAndState.state"
          control={control}
          disableUnknown={false}
          showEmptyOption={false}
        />
      </Box>
      <ConfirmDialog
        title={t("print-template-warning-title")}
        open={showResetDialog}
        message={t("print-template-warning-message")}
        handleConfirm={handleConfirm}
        handleReject={handleReject}
        handleAlternativeConfirm={handleAlternativeConfirm}
        ariaLabel="print-template-warning-title"
        ariaDescription="print-template-warning-message"
        confirmBtnText={t("delete")}
        alternativeConfirmBtnText={t("import")}
        showAlternativeConfirmButton={true}
        loading={loading}
      />
    </Box>
  );
};

export default PrintTemplateAndStatus;
