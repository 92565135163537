import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import GenericMultipleSelect from "./GenericMultipleSelect";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { DropDownItem } from "../../../api/basedataApiClient";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../../../helper/securityUtil";

interface StatusDropdownProps {
  name: string;
  control: any;
  localStorage?: boolean;
  disableUnknown?: boolean;
  showEmptyOption?: boolean;
}

interface StatusProps {
  id: number;
  name: STATUS_TYPE;
  backgroundColor?: string;
}

export enum STATUS_TYPE {
  UNKNOWN = "UNKNOWN",
  IN_GRAPHIC = "IN_GRAPHIC",
  FINISHED = "FINISHED",
  FINISHED_MEDIA = "FINISHED_MEDIA",
  IN_PROGRESS = "IN_PROGRESS",
  IN_RELEASE = "IN_RELEASE",
  CRITICAL = "CRITICAL",
  READY = "READY",
}

const STATUS: StatusProps[] = [
  {
    id: 1,
    name: STATUS_TYPE.UNKNOWN,
    backgroundColor: "grey",
  },
  {
    id: 2,
    name: STATUS_TYPE.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    id: 3,
    name: STATUS_TYPE.READY,
    backgroundColor: "orange",
  },
  {
    id: 4,
    name: STATUS_TYPE.IN_RELEASE,
    backgroundColor: "lightgreen",
  },
  {
    id: 5,
    name: STATUS_TYPE.FINISHED,
    backgroundColor: "green",
  },
  {
    id: 6,
    name: STATUS_TYPE.FINISHED_MEDIA,
    backgroundColor: "lightblue",
  },
  {
    id: 7,
    name: STATUS_TYPE.IN_GRAPHIC,
    backgroundColor: "blue",
  },
  {
    id: 8,
    name: STATUS_TYPE.CRITICAL,
    backgroundColor: "red",
  },
];

const STATUS_CLIENT: StatusProps[] = [
  {
    id: 1,
    name: STATUS_TYPE.IN_PROGRESS,
    backgroundColor: "gold",
  },
  {
    id: 2,
    name: STATUS_TYPE.FINISHED,
    backgroundColor: "green",
  },
  {
    id: 3,
    name: STATUS_TYPE.IN_GRAPHIC,
    backgroundColor: "blue",
  },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StatusDropdown = ({
  name,
  control,
  disableUnknown = false,
}: StatusDropdownProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [statusOptions, setStatusOptions] = useState<StatusProps[]>([]);

  const filterStatus = (status: StatusProps[]) => {
    return disableUnknown
      ? status.filter((status) => status.name !== "UNKNOWN")
      : status;
  };

  const onLoad = async () => {
    if (keycloak.authenticated) {
      if (SecurityUtil.isMarktbetreiber(keycloak)) {
        setStatusOptions(filterStatus(STATUS_CLIENT));
      } else {
        setStatusOptions(filterStatus(STATUS));
      }
    } else {
      setStatusOptions(filterStatus(STATUS));
    }
  };

  useEffect(() => {
    onLoad();
  });

  return (
    <GenericMultipleSelect
      name={name}
      label={t("status")}
      control={control}
      options={statusOptions as DropDownItem[]}
      localStorage={true}
      renderOption={(props, option, { selected }, onChange) => (
        <li {...props} key={option.id}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <Chip
            size="small"
            label={``}
            style={{
              backgroundColor: option.backgroundColor,
              width: "16px",
              height: "16px",
              marginRight: "5px",
            }}
          />
          {t(option.name.toLowerCase().replace(/_/g, "-") + "-status-option")}
        </li>
      )}
      placeholder={(value: any) => {
        return value
          ? value
              .map(
                (elem: any) =>
                  t(
                    elem?.name.toLowerCase().replace(/_/g, "-") +
                      "-status-option"
                  ) || elem
              )
              .join(", ")
          : "";
      }}
    />
  );
};

export default StatusDropdown;
