import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import {ArticleAssignment, ArticleHistoryAssignment} from "../../../api/detailedPlanningApiClient";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface HistoryTooltipProps {
    propName?: string;
    articleHistoryAssignment?: ArticleHistoryAssignment | null;
    article?: ArticleAssignment | null;
    printingHouseHistory?: string;
}

const HistoryTooltip = ({
                            propName,
                            articleHistoryAssignment,
                            article,
                            printingHouseHistory,
                        }: HistoryTooltipProps) => {

    const {t} = useTranslation();
    let timeout: ReturnType<typeof setTimeout>;
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    return (
        <>
            {
                // @ts-ignore
                (printingHouseHistory || propName === undefined && articleHistoryAssignment || (articleHistoryAssignment && articleHistoryAssignment[propName] !== article[propName] && !articleHistoryAssignment.externalAdded)) && (
                    <Tooltip
                        arrow
                        onMouseEnter={() => {
                            timeout = setTimeout(
                                () => setTooltipIsOpen(true),
                                100
                            );
                        }}
                        onMouseLeave={() => {
                            clearTimeout(timeout);
                            setTooltipIsOpen(false);
                        }}
                        open={tooltipIsOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                            <>
                                <b>{t("external-changed") + ", " + t("original-value")}:</b>
                                <br/>

                                {
                                    propName === undefined && articleHistoryAssignment ?
                                        `${articleHistoryAssignment.unlistedArticleName || articleHistoryAssignment.articleName} 
                                        ${articleHistoryAssignment.eyeCatcher ? t("eye-catcher") + ":" + articleHistoryAssignment.eyeCatcher : ""} 
                                        ${articleHistoryAssignment.articleRange ? t("articlerange") : ""} 
                                        lvp:${articleHistoryAssignment.lvpPrice / 100} 
                                        avp:${articleHistoryAssignment.avpPrice / 100} 
                                        ${articleHistoryAssignment.processingNote ? t("implementation-note") + ":" + articleHistoryAssignment.processingNote : ""}` :
                                        // @ts-ignore
                                        articleHistoryAssignment && (propName === "avpPrice" || propName === "lvpPrice") ? "€ " + (articleHistoryAssignment[propName] / 100) :
                                            // @ts-ignore
                                            articleHistoryAssignment ? articleHistoryAssignment[propName] || t("empty-field") : printingHouseHistory ? printingHouseHistory :
                                                t("empty-field")
                                }
                            </>
                        }
                    >
                        <InfoOutlinedIcon
                            color="primary"
                            style={{
                                fontSize: "18px",
                                margin: "0px 5px -2px 10px",
                                position: "absolute",
                                right: "0",
                                top: "50%",
                                transform: "translateY(-50%)",
                                marginRight: printingHouseHistory ? "10px" : "5px",
                            }}
                        />
                    </Tooltip>
                )}
        </>
    );
};

export default HistoryTooltip;
