import {Box, Button, Typography, makeStyles} from "@material-ui/core";

import {useTranslation} from "react-i18next";

import ContentCard from "../../../components/ContentCard";
import PrintingTabs from "../components/PrintingTabs";
import {Link, useLocation} from "react-router-dom";

interface CustomerProps {
    year: string;
    weekOfYear: string;
    customerId: string;
    customerName: string;
    clientele: string;
    importVersion: string;
    status: string;
}

const navlinkStyle = {
    color: "white",
    textDecoration: "none",
    width: "100%",
};

const Customer = ({
                      year,
                      weekOfYear,
                      customerId,
                      customerName,
                      clientele,
                      importVersion,
                      status,
                  }: CustomerProps) => {
    const {t} = useTranslation();
    const classes = useStyles();
    let location = useLocation();
    const base = location.pathname.split("/")[1];
    return (
        <ContentCard>
            <Box className={classes.container}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                >
                    <Box>
                        <Typography component="span" variant="h6">
                            {clientele}
                        </Typography>
                        <Typography
                            component="div"
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {customerName}
                        </Typography>
                    </Box>

                    <Button variant="contained" color="primary">
                        <Link
                            style={navlinkStyle}
                            to={"/" + base + "/details"}
                            state={{
                                year: year,
                                weekOfYear: weekOfYear,
                                importVersion: importVersion,
                                clientele: clientele,
                            }}
                        >
                            {t("for-detailed-planning")}
                        </Link>
                    </Button>
                </Box>
                <PrintingTabs
                    year={year}
                    weekOfYear={weekOfYear}
                    clientele={clientele}
                    customerId={customerId}
                    importVersion={importVersion}
                    status={status}
                />
            </Box>
        </ContentCard>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(100vh - 115px)",
        overflow: "auto",
    },

    boxHeight: {
        height: theme.spacing(2),
    },
}));

export default Customer;
