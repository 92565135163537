import {Box, IconButton} from "@mui/material";
import GenericTextField from "./GenericTextField";
import GenericSwitch from "../../ad-planning/dropdowns/GenericSwitch";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SmsFailedIcon from '@mui/icons-material/SmsFailed';

import {Chip, makeStyles, TableCell, Tooltip, Typography} from "@material-ui/core";
import CurrencyInput from "../components/CurrencyInput";
import {useDrop} from "react-dnd";
import {InsertionArticle} from "./ArticleRow";
import {
    ArticleAssignment, ArticleHistoryAssignment,
    PreviousAvpPriceResult,
} from "../../../api/detailedPlanningApiClient";
import {useTranslation} from "react-i18next";
import LinkedPositionsDropdown from "../dropdowns/LinkedPositionsDropdown";
import {useFormContext} from "react-hook-form";
import {FormValues} from "../../layout/DetailedPlanningDetailsLayout";
import {UnlistedArticleProps} from "./UnlistedArticle";
import {RefObject, useEffect} from "react";
import {Identifier} from "dnd-core";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import {PermissionGuard} from "../../../components/PermissionGuard";
import * as React from "react";
import HistoryTooltip from "./HistoryTooltip";
import SecurityUtil from "../../../helper/securityUtil";

interface FilledRowProps {
    index: number;
    selectedFieldIndex: number | null;
    article: ArticleAssignment;
    articleIndex: number;
    pageIndex: number;
    getAvpPriceResult: (articleId: number) => void;
    removeAssignment: (index: number, articleIndex: number) => void;
    fieldRowName: string;
    handleUnlistedDrop: (item: UnlistedArticleProps, index: number) => void;
    handleDrop: (
        insertionArticle: InsertionArticle,
        positionIndex: number,
        avpPriceResult: PreviousAvpPriceResult | undefined
    ) => void;
    verticalRef: RefObject<HTMLDivElement>;
    handlerId: Identifier | null;
    isActiveDrop: { isOver: boolean; canDrop: boolean };
    articleHistoryAssignment: ArticleHistoryAssignment | null;
}

const useStyles = makeStyles((theme) => ({
    currency: {
        width: "100%",
    },
    hasHistory: {
        background: "rgba(255, 0, 0, 0.18)",
        display: "flex",
        position: "relative",
    },
    processingNoteHint: {
        position: "absolute",
        width: "22px",
        bottom: "55%",
        color: "rgba(255, 0, 0, 0.5)",
    }
}));

const FilledRow = ({
                       isActiveDrop,
                       index,
                       pageIndex,
                       selectedFieldIndex,
                       getAvpPriceResult,
                       removeAssignment,
                       article,
                       articleIndex,
                       fieldRowName,
                       handleUnlistedDrop,
                       handleDrop,
                       verticalRef,
                       handlerId,
                       articleHistoryAssignment,
                   }: FilledRowProps) => {
    const {t} = useTranslation();
    const {control, setFocus, getValues, watch, setValue} =
        useFormContext<FormValues>();
    const values = getValues();
    const classes = useStyles();

    const [{isOver, canDrop}, dropRef] = useDrop(() => ({
        accept: ["article", "unlistedArticle"],
        drop: (item: any) => {
            if (item.unlistedArticleName) {
                handleUnlistedDrop(item as UnlistedArticleProps, index);
            } else if (item.article) {
                (async () => {
                    const avpPrice = (await getAvpPriceResult(
                        item.article.articleId as number
                    )) as any;
                    handleDrop(item, index, avpPrice);
                })();
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const getPriceDifference = (
        lvpPrice: number | null,
        avpPrice: number | null
    ) => {
        if (lvpPrice && avpPrice) {
            lvpPrice = lvpPrice / 100;
            avpPrice = avpPrice / 100;

            const diff = lvpPrice - avpPrice;
            if (diff < 1) return ((diff / lvpPrice) * 100).toFixed(0) + "%";
            return diff.toFixed(2) + "€";
        } else {
            return 0 + "€";
        }
    };

    const handleEnter = (event: KeyboardEvent, field: String) => {
        if (event.key.toLowerCase() === "enter") {
            const values = getValues();
            const positions = values.pages[pageIndex].positions;
            for (let i = index; i < positions.length; i++) {
                if (positions[i] !== undefined) {
                    if (
                        positions[i].articleAssignments !== undefined &&
                        positions[i]?.articleAssignments.length - 1 !== articleIndex
                    ) {
                        setFocus(
                            (`pages.${pageIndex}.positions.${i}.articleAssignments.${
                                    articleIndex + 1
                                }` +
                                "." +
                                field) as any
                        );
                        break;
                    } else if (
                        positions[i + 1] !== undefined &&
                        positions[i + 1].articleAssignments
                    ) {
                        setFocus(
                            (`pages.${pageIndex}.positions.${i + 1}.articleAssignments.0` +
                                "." +
                                field) as any
                        );
                        break;
                    }
                }
            }
        }
    };

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            const trimName = name?.substring(0, name.lastIndexOf("."));
            if (
                values.pages[pageIndex]?.positions[index]?.articleAssignments[
                    articleIndex
                    ]?.autoPlaced &&
                type === "change" &&
                trimName ===
                `pages.${pageIndex}.positions.${index}.articleAssignments.${articleIndex}`
            ) {
                setValue(
                    `${trimName}.autoPlaced` as `pages.${number}.positions.${number}.articleAssignments.${number}.autoPlaced`,
                    false
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [watch, articleIndex, index, pageIndex, setValue, values.pages]);

    return (
        <>
            <TableCell
                key={"drop-filled-table-cell-" + index}
                ref={dropRef}
                style={{
                    fontWeight: "bold",
                    border: 0,
                    boxShadow: isOver ? "inset 0px 0px 3px #1976d2" : "none",
                    backgroundColor: canDrop ? "rgba(244, 244, 244, 1)" : undefined,
                    color: selectedFieldIndex === index ? "blue" : undefined,
                }}
            >
                {index + 1}
                {articleIndex !== 0 ? "." + articleIndex : ""}
                {articleHistoryAssignment && articleHistoryAssignment.processingNote !== article.processingNote && !articleHistoryAssignment.externalAdded && (
                    <SmsFailedIcon className={classes.processingNoteHint}/>
                )}
            </TableCell>
            <TableCell align="center" style={{padding: "0"}}>
                {article.nomConversion !== 0 && article.nomConversion !== undefined && (
                    <Typography
                        variant="caption"
                        style={{
                            color: true ? "red" : "green",
                        }}
                    >
                        {article.actConversion} {t("of")} {article.nomConversion}
                    </Typography>
                )}
            </TableCell>
            <TableCell style={{position: "relative"}}>
                <PermissionGuard
                    hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                    {article.autoPlaced && (
                        <HdrAutoIcon
                            color="primary"
                            style={{
                                fontSize: "13px",
                                position: "absolute",
                                left: "0",
                                top: "50%",
                                transform: "translate(0%, -50%)",
                            }}
                        />
                    )}
                </PermissionGuard>
                {article && article.unlistedArticleName}
                <PermissionGuard
                    hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                    {article.coverPage && (
                        <Chip
                            size="small"
                            label="TS"
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}

                    {article.hero && (
                        <Chip
                            size="small"
                            label="H"
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}

                    {article.tailorMade && (
                        <Chip
                            size="small"
                            label="TM"
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}

                    {article.onlineCampaign && (
                        <Chip
                            size="small"
                            label="OMA"
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}

                    {article.offerDiscount && (
                        <Chip
                            size="small"
                            label="AR"
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}

                    {article.customQuality && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                    {article.customQuality2 && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription2}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                    {article.customQuality3 && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription3}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                    {article.customQuality4 && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription4}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                    {article.customQuality5 && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription5}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                    {article.customQuality6 && (
                        <Chip
                            size="small"
                            label={article.customQualityDescription6}
                            color="primary"
                            style={{fontSize: "12px", padding: 1, marginLeft: 5, marginBottom: 1}}
                        />
                    )}
                </PermissionGuard>
            </TableCell>
            <TableCell
                className={articleHistoryAssignment && articleHistoryAssignment.eyeCatcher !== article.eyeCatcher && !articleHistoryAssignment.externalAdded ? classes.hasHistory : ""}>
                {article && (
                    <>
                        <GenericTextField
                            name={
                                `${fieldRowName}.articleAssignments.${articleIndex}.eyeCatcher` as const
                            }
                            control={control}
                            onKeyDown={(e) => {
                                handleEnter(e, "eyeCatcher");
                            }}
                        />
                        <HistoryTooltip propName={"eyeCatcher"} articleHistoryAssignment={articleHistoryAssignment}
                                        article={article}/>
                    </>
                )}
            </TableCell>

            <TableCell
                className={articleHistoryAssignment && articleHistoryAssignment.articleRange !== article.articleRange && !articleHistoryAssignment.externalAdded ? classes.hasHistory : ""}>
                {article && (
                    <>
                        <GenericSwitch
                            name={
                                `${fieldRowName}.articleAssignments.${articleIndex}.articleRange` as const
                            }
                            control={control}
                        />
                        <HistoryTooltip propName={"articleRange"} articleHistoryAssignment={articleHistoryAssignment}
                                        article={article}/>
                    </>
                )}
            </TableCell>
            <TableCell align="right"
                       className={articleHistoryAssignment && articleHistoryAssignment.lvpPrice !== article.lvpPrice && !articleHistoryAssignment.externalAdded ? classes.hasHistory : ""}
                       style={{display: "table-cell"}}>
                {article && (
                    <>
                        <CurrencyInput
                            name={
                                `${fieldRowName}.articleAssignments.${articleIndex}.lvpPrice` as const
                            }
                            control={control}
                            onKeyDown={(e) => {
                                handleEnter(e, "lvpPrice");
                            }}
                            textAlighEnd={true}
                            className={classes.currency}
                        />
                        <HistoryTooltip propName={"lvpPrice"} articleHistoryAssignment={articleHistoryAssignment}
                                        article={article}/>
                    </>
                )}
            </TableCell>
            <Tooltip
                arrow
                placement="right"
                title={
                    article?.avpYear == null && article?.avpWeek == null
                        ? ""
                        : `KW ${article.avpWeek} / ${article.avpYear}`
                }
            >
                <TableCell align="right"
                           className={articleHistoryAssignment && articleHistoryAssignment.avpPrice !== article.avpPrice && !articleHistoryAssignment.externalAdded ? classes.hasHistory : ""}>
                    {article && (
                        <>
                            <CurrencyInput
                                name={
                                    `${fieldRowName}.articleAssignments.${articleIndex}.avpPrice` as const
                                }
                                control={control}
                                onKeyDown={(e) => {
                                    handleEnter(e, "avpPrice");
                                }}
                                textAlighEnd={true}
                                className={classes.currency}
                            />
                            <HistoryTooltip propName={"avpPrice"} articleHistoryAssignment={articleHistoryAssignment}
                                            article={article}/>
                        </>
                    )}
                </TableCell>
            </Tooltip>
            <TableCell align="right">
                <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                    {article &&
                        article.lvpPrice &&
                        article.avpPrice &&
                        getPriceDifference(article.lvpPrice, article.avpPrice)}
                </PermissionGuard>
            </TableCell>
            <TableCell
                ref={articleIndex === 0 ? verticalRef : null}
                data-handler-id={articleIndex === 0 ? handlerId : null}
                key={`draggable-cell-${index}`}
                style={{
                    fontWeight: "bold",
                    border: 0,
                    backgroundColor: isActiveDrop.canDrop
                        ? "rgba(244, 244, 244, 1)"
                        : articleHistoryAssignment && articleHistoryAssignment.externalAdded ? "none" : "white",
                    boxShadow: isActiveDrop.isOver ? "inset 0px 0px 3px #1976d2" : "none",
                    cursor: articleIndex !== 0 ? "auto" : "move",
                }}
            >
                {article && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                                <DragIndicatorIcon
                                    sx={{
                                        padding: 0,
                                        opacity: articleIndex !== 0 ? "0" : "1",
                                    }}
                                />
                            </PermissionGuard>
                            <IconButton
                                size="small"
                                aria-label={t("delete")}
                                sx={{color: "#b71c1c"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeAssignment(index, articleIndex);
                                }}
                            >
                                <RemoveSharpIcon/>
                            </IconButton>
                            <PermissionGuard hideForRoles={[SecurityUtil.MARKTBETREIBER]}>
                                {articleIndex === 0 && (
                                    <LinkedPositionsDropdown
                                        index={index}
                                        pageIndex={pageIndex}
                                        name={`${fieldRowName}.linkedPositions` as const}
                                    />
                                )}
                            </PermissionGuard>
                        </Box>
                    </>
                )}
            </TableCell>
        </>
    );
};

export default FilledRow;
