import i18n from "../i18n";

import DetailedPlanningPage from "../pages/detailed-planning/DetailedPlanningPage";
import ClientelePage from "../pages/detailed-planning/ClientelePage";
import CustomerPage from "../pages/detailed-planning/CustomerPage";

const DetailedPlanningRoutes = [
  {
    path: "/" + i18n.t("detailed-planning").toLowerCase(),
    element: <DetailedPlanningPage />,
  },
  {
    path: "/" + i18n.t("detailed-planning").toLowerCase() + "/clientele",
    element:  <ClientelePage/>,
  },
  {
    path:
      "/" +
      i18n.t("detailed-planning").toLowerCase() +
      "/clientele/:customerId",
    element: <CustomerPage/>
  },
];

export default DetailedPlanningRoutes;
